.adsbanner-modal {
    max-width: 740px;
    .modal-header {
        display: none !important;
    }
    .fa-times {
        cursor: pointer;
        margin-right: 10px;
    }
    .panel {
        margin-bottom: 0 !important;
    }
    .panel-filter {
        padding-left: 0px;
    }
    .empty-filter-data {
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .banner-line-description {
        display: flex;
        margin-bottom: 10px;
        & > div {
            max-height: 100px;
            min-width: 150px;
            display: flex;
            align-items: center;
        }
        &.banner-container > div {
            margin-top: 10px;
        }
        img {
            height: 100%;
        }
    }
    .banner-buttons-block {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        .cancel {
            margin-left: 5px;
            height: 33px;
        }
    }
    .input-container {
        margin-right: 10px;
        label {
            width: 140px;
        }
    }
    .banner-input-field {
        width: 100% !important;
        .form-group {
            width: 100%;
        }
    }
    .textarea-wrapper {
        flex-direction: column;
    }
    .count-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        color: #999;
    }
    .date-field {
        & > input {
            width: 160px;
        }
    }
}
