$borderColor: #bdc1c3;
$blueCircleColor: #1b6ba8;
$redRemoveColor: #dd2c00;
$grayFontColor: #999;
$darkFontColor: #323232;
$white: #fff;
$mobileWidth: 766px;

#add-ads-button {
    height: 25px;
    width: 27px;
    margin-right: 10px;
    margin-top: -5px;
    padding: 0 8px;
    line-height: 25px;
}

.my-ads_ {
    &header {
        padding: 10px 15px;
        box-sizing: border-box;
        .add-ads-link {
            text-decoration: none;
            outline: none;
            background-color: transparent;
            .link-text {
                color: $white;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .bordered-filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 15px;
        .filter {
            cursor: pointer;
            .all {
                color: $darkFontColor;
                font-weight: 500;
            }
            .active {
                color: $blueCircleColor;
            }
            .unactive {
                color: $grayFontColor;
            }
            .selected {
                text-decoration: underline;
            }
        }
        .label-dropdown-container {
            display: flex;
            align-items: center;
            span {
                margin-right: 20px;
            }
            .sort-type-container {
                width: 160px;
                height: 30px;
            }
        }
    }
    .table-my-ads {
        border-top: 2px solid $borderColor;
        .table-ads-body {
            tr {
                td {
                    vertical-align: middle !important;
                    padding: 0 5px;
                    border-left: 0.5px solid $borderColor;
                    border-right: 0.5px solid $borderColor;
                    &:first-child {
                        border-left: 2px solid $borderColor;
                    }
                    &:last-child {
                        border-right: 2px solid $borderColor;
                    }
                    @media (max-width: $mobileWidth) {
                        border: 0;
                    }
                    cursor: pointer;
                    .bullet {
                        background-color: $blueCircleColor;
                    }
                }
                .td-bullet {
                    cursor: default;
                    text-align: center;
                    .bullet {
                        width: 10px;
                        height: 10px;
                        display: block;
                        padding: 0;
                        margin: 0 auto;
                    }
                    a {
                        text-align: center;
                    }
                    @media (max-width: $mobileWidth) {
                        margin-left: -9px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }
                }
                .td-date {
                    width: 84px;
                    @media (max-width: $mobileWidth) {
                        margin-left: 40px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        color: $grayFontColor;
                    }
                }
                .td-info {
                    max-width: 528px;
                    word-wrap: break-word;
                    @media (max-width: $mobileWidth) {
                        padding: 3px 12px;
                    }
                }
                .td-views {
                    width: 92px;
                    @media (max-width: $mobileWidth) {
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }
                }
                .td-edit {
                    color: $blueCircleColor;
                    border-right: none;
                    width: 53px;
                }
                .td-remove {
                    color: $redRemoveColor;
                    border-left: none;
                    width: 53px;
                }
                &:last-child {
                    td {
                        border-bottom: 2px solid $borderColor;
                    }
                    td:first-child {
                        border-bottom-left-radius: 10px;
                    }
                    td:last-child {
                        border-bottom-right-radius: 10px;
                    }
                }
            }
        }
    }
}