.auto-park-add_wrapper {
  .auto-park-add_trailer-row {
    transition: all 500ms ease;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  .auto-park-add_no-trailer-row {
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    border-top: none;
  }
  @media only screen and (max-width: 599px) {
    .col-mob-lg {
      width: 100% !important;
    }
    .image-upload-field_wrapper {
      height: 80px;
      width: 100%;
    }
  }
  .ico-placeholder {
    width: 100%;
    height: 60px;
    background-image: url('../../../../../images/buse.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .ico-truck-buse {
    background-image: url('../../../../../images/buse.png');
  }
  .ico-truck-cargo {
    background-image: url('../../../../../images/truck.png');
  }
  .ico-truck-tractor {
    background-image: url('../../../../../images/teagach.png');
  }
  .ico-truck-cargo-lg {
    background-image: url('../../../../../images/truck-lg.png');
  }
  .disabled-layer {
    position: relative;
  }
  .layer-overlay {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: 0px 0px 10px 10px;
  }

  .add-transport-textarea {
    height: 100px;
    margin-bottom: 10px;
  }
}
.inputs-no-p{
  input[type="checkbox"] + label{
    margin-bottom: 0px;
  }
}