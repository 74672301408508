.profile_wrapper {

  // .panel-content{
  //   text-align: center;
  // }

  input[readonly] {
    background: #eee;
  }
  
  .profile_info {
    min-height: 200px;
    padding: 20px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


