$white: #fff;
$iconColor: #1b6ba8;

#add-employes-button {
  height: 25px;
  width: 27px;
  margin-right: 10px;
  margin-top: -5px;
  padding: 0 8px;
  line-height: 25px;
}

.company-employee-list_ {
  margin-bottom: 15px;
  &header {
    padding: 10px 15px;
    box-sizing: border-box;
    .add-ads-link {
      text-decoration: none;
      outline: none;
      background-color: transparent;
      .link-text {
        color: $white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .panel-filter {
    .page-quantity-container {
      display: flex;
      align-items: center;
      float: right;
      span {
        margin-right: 10px;
      }
      .page-quantity-dropdown {
        width: 60px !important;
        height: 30px;
      }
    }
  }
  table {
    thead {
      th {
        padding: 5px;
        text-align: center;
      }
    }
    tbody {
      td {
        vertical-align: middle;
        &:last-child {
          a {
            color: $iconColor !important;
          }
        }
        .name {
          width: 190px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
