$mobile-width: 767px;

.transport-modal {
  .modal-header {
    padding: 0px;
  }
  .close {
    right: 13px !important;
    top: 5px !important;
  }
  .transport-modal-content {
    overflow-y: scroll;
    padding: 0px 5px 5px 5px;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }
  .transport-slider-block {
    width: 730px;
    height: 310px;
    margin: auto;
    display: flex;

    @media (max-width: $mobile-width) {
      width: auto;
      height: 210px;
    }

    .transport-slider-photo {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }

  .arrows-space {
    height: 50px;
    border-bottom: 1px solid #cbcfd1;
  }

  .slick-slider {
    width: auto;
    height: auto;

    @media (max-width: $mobile-width) {
      max-width: 90vw;
      margin: auto;
    }
  }

  .slick-active div {
    outline: none;
  }

  .slick-prev,
  .slick-next {
    margin-top: 0;
    top: 105%;
    &::before {
      color: #000;
    }
  }

  .slick-prev {
    z-index: 1;
    left: 44%;

    @media (max-width: $mobile-width) {
      left: 37%;
    }
  }

  .slick-next {
    right: 44%;

    @media (max-width: $mobile-width) {
      right: 37%;
    }
  }

  .car-img-container {
    @media (max-width: 992px) {
      width: 130px;
      height: 100px;
    }
  }
}
