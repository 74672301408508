.company-edit_modal {
  max-width: 590px;

  > .modal-content {
    overflow: hidden;

    > .modal-header {
      background: #921d00;
      padding: 10px 15px;
      color: #fff;

      > .modal-title {
        text-align: left !important;
        font-weight: normal !important;
      }
      > .close {
        color: #fff;
        opacity: 1;
        transition: color 0.3s;

        &:hover {
          color: #f56709;
        }
      }
    }
  }
}
.upload-container {
  display: flex;
  align-items: self-start;
  min-width: 230px;
  justify-content: space-between;
}
.upload-children {
  display: flex;
  margin-right: 10px;
}
label {
  &.required {
    &::after {
      content: "*";
      color: #dd2c00;
      margin-left: 2px;
    }
  }
}

.add-edit-company-form {
  .photo-block {
    width: 50px;
    height: 50px;
  }
  .license-uploader-container {
    display: flex;
    .image-upload-field_wrapper.photo-block {
      margin-right: 10px;
    }
  }

  label {
    display: flex;
  }
  .alert-banner {
    .panel.panel-warning {
      margin: 10px 0px;
    }
  }
  @media screen and (max-width: 800px) {
    .button-block {
      width: auto;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
.cursor-disabled {
  cursor: not-allowed !important;
  background: #e3e3e3 !important;
  opacity: 0.5;
  a {
    cursor: not-allowed !important;
  }
}
.cursor-pointer {
  cursor: pointer;
  div {
    cursor: pointer;
  }
}
.tooltip-verif-company {
  margin-right: 5px;
}

.company-profile {
  .company-description-length {
    float: right;
    font-weight: 700;
  }
  .error-text {
    color: #ff0000;
  }
}

.contact-details {
  .validation-message {
    margin-top: -40px;
  }
}

.chosen-single {
  &.read-only-input {
    background: #eee;
  }
}
