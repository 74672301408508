@import "../../../palette/palette.scss";

table.table-cargo {
  cursor: pointer;
  & + div > .banner-text-url,
  .hide-banner {
    display: none;
  }
  tbody > tr > td {
    border-top: 2px solid #bdc1c3;
  }

  &.no-borders {
    border-bottom: none;
    td:first-child {
      border-left: none;
    }
    td:last-child {
      border-right: none;
    }
  }
  td {
    border-bottom: 4px;
    @media screen and (max-width: 991px) {
      border-top: none !important;
    }
  }
  .cursor-default {
    cursor: default !important;
  }
  tr.table-line {
    max-height: 44px;
    @media screen and (max-width: 991px) {
      max-height: 100%;
    }
  }
  @media screen and (min-width: 768px) {
  td.td-country {
    font-size: 12px;
    vertical-align: middle !important;
    text-align: center;
  }
}
  td.td-city {
    vertical-align: middle !important;
    white-space: nowrap;
    span {
      max-width: 226px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (min-width: 767px)  {
        max-width: 160px;
      }
      }
      }
    }
  td.td-oncoming {
    text-align: center;
  }
  td.td-date {
    min-width: 55px;
    vertical-align: middle !important;
  }
  td.td-info {
    max-width: 300px;
    .fa,
    .ico {
      margin-left: 5px;
    }

    &.no-max-width {
      max-width: 0;
    }

    @media screen and (max-width: 1320px) {
      max-width: 250px !important;
    }
    @media screen and (max-width: 991px) {
      max-width: 100% !important;
    }
    @media screen and (max-width: 767px) {
      .td-info-flex {
        flex-direction: column;
      }
    }
  }

  td.td-company {
    max-width: 227px;
    min-width: 227px;
    .has-tooltip {
      width: 100%;
      span {
        width: fit-content;
      }
      span:last-child {
        overflow: hidden;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
    .fa-ban {
      color: #964b00;
      margin-left: 1px;
      margin-right: 3px;
    }

    .date .meta {
      font-size: 11px;
    }

    @media screen and (max-width: 1320px) {
      max-width: 180px !important;
      min-width: 180px !important;
    }
  }
  td.info {
    width: 351px;

    @media screen and (min-width: 1320px) {
      width: 300px !important;
    }
  }
  td.td-favourite {
    border-left: none;
    span {
      transition: 0.2s;
      &:hover {
        transform: translateY(-3px);
        padding-bottom: 3px;
      }
    }
    @media screen and (max-width: 991px) {
      text-align: right;
    }
  }
  .show-info-modal {
    position: relative;
  }
  td.restricted {
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 991px) {
      text-align: left;
    }
  }
  .show-info-modal:hover + .tooltip {
    display: block !important;
  }
  .meta-data {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 98%;
    white-space: nowrap;

}
@media screen and (min-width: 768px) {
  .meta-data {
      margin-top: 5px;
  }
}
.verified-company {
  padding-left: 15px;
}

.panel-content-table {
  border-top: none;
}

.table-no-entries {
  height: 60px;
  flex-direction: column;
  align-items: center;
}

.panel-content-table-cargo .table-no-entries {
  position: relative;
  top: 5px;
  border: 2px solid #bdc1c3;;
  border-radius: 10px;
}

.cargo-transport-table_tr-header {
  > td {
    background-color: #36454f !important;
    color: #ffffff;
    border-bottom: 1px solid #36454f;
    border-top: none !important;
    vertical-align: middle !important;

    &:first-of-type {
      border-left-color: #36454f;
    }
    &:last-of-type {
      border-right-color: #36454f;
    }
  }
}

.cargo-item_wrapper .favorite {
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    margin-top: -3px;
  }
}

.cargo-transport-table_modal {
  max-width: 730px;
  max-height: 90%;

  @media screen and (max-width: 360px) {
    .modal-content {
      transform: scale(0.85) !important;
    }
  }

  .widget-header_wrapper h4 {
    font-weight: 400;
  }

  .panel-static,
  .cargo-item_wrapper {
    height: 100%;
    margin-bottom: 0;
    border-radius: 10px;
  }

  .panel-body {
    height: calc(100% - 50px);
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }
}
@media screen and (max-width: 360px){
  .cargo-transport-table_modal{
    max-width: 100%;
  }
  .cargo-transport-table_modal .modal-content{
    transform: scale(0.98) !important;
  }
}

@media screen and (min-width: 361px) and (max-width: 760px){
  .cargo-transport-table_modal{
    max-width: 100%;
    margin: 0 5px;
  }
}

.company-users {
  max-height: 16px;
  width: 58% !important;
}
@media screen and (min-width: 992px)  {
  .company-users {
    overflow: hidden;
  }
}
.label-items-found {
  font-size: 16px;
}

.full-tooltip {
  width: auto !important;
  max-width: inherit;
  .company-info-cell {
    display: block;
    float: left;
  }
}
.popover {
  max-width: max-content !important;
}

.td-info_volume {
  margin-left: 10px;
}

.my-offers {
  .offers_mobile_off {
    @media screen and (max-width: 1320px) and (min-width: 992px) {
      display: none;
    }
  }
  .offers_mobile_on {
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
  tr {
    @media screen and (max-width: 991px) {
      position: relative;
    }
  }
  td {
    padding: 5px 7px;
    .td-city {
      min-width: 160px;
    }
  }
  .td-info {
    max-width: 314px !important;
  }
  .td-oncoming-new {
    min-width: 50px;
    vertical-align: middle;
    text-decoration: underline;
  }
  .td-oncoming-total {
    min-width: 50px;
    vertical-align: middle;
    text-decoration: underline;
  }
  .td-views {
    min-width: 50px;
    vertical-align: middle;
  }
  .td-options {
    text-align: right !important;
    white-space: nowrap;
    min-width: auto;
    @media screen and (max-width: 1320px) {
      min-width: 71px;
    }
    @media screen and (max-width: 991px) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    i {
      margin-left: 5px;
    }
  }
}
.tr-spacing {
  height: 40px;
  &:last-child {
    height: 0;
  }
}
.cargo-transport-table_tr-oncoming {
  td {
    border: none;
    &:nth-child(1) {
      border-left: 2px solid #bdc1c3;
    }
  }
}
