.long-text {
  word-break: break-word;
  &.redirect-reviews{
    text-decoration: unset;
    color: #323232;
  }
}

.name-lg {
  width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}