.claim-actions-container {
    .action-button {
        padding: 5px;
    }
    .claim-actions-title {
        font-size: 16px;
    }
    .reject-button {
        background-color: #36454f;
        color: #fff;
    }
    .icon-share {
        cursor: pointer;
    }
}

.claim-parent-container {
    position: relative;
    .claim-spinner-container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: rgba(000, 000, 000, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .claim-approved {
        color: #04725e;
        b {
            font-size: 10px;
            color: #f56709;
        }
    }
    .claim-not-approved {
        color: #d52424;
    }
    .panel-message-header-right {
        .fa-check-circle {
            margin-right: 10px;
        }
    }
    .claim-type label {
        font-weight: 700;
        font-size: 13px;
        margin-right: 5px;
    }
}
