.block-line {
  td {
    vertical-align: middle !important;
  }

  .td-user-image img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }

  .name {
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}