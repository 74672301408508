.textarea-disabled {
  background: #ececec;
}
.tooltip-trigger {
  margin-right: 5px;
}
.qoute-message {
  color: #36454f;
  border: 1px solid #36454f;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #e3e3e3;
}
.remove-quote {
  float: none;
  display: block;
  margin-top: 10px;
}
.quote-message {
  font-weight: 700;
}
#addCommentBlock .panel-body {
  &::before {
    content: none;
  }
  .panel-content-row {
    border-top: none;
  }
}

.no-mb{
  margin-bottom: 0px !important;
}