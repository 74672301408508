.newslatter {
  .single-comment-box {
    border-bottom: 1px solid #e3e3e3;
    &:last-child {
      border-bottom: none;
    }
    .panel-content-row p {
      &.widget-comment-title {
        display: flex;
      }
    }
  }

  .theme-title {
    color: #1b6ba8;
    text-decoration: none;
    outline: none;
    display: inline-block !important;
    text-transform: uppercase;
    cursor: pointer;
    margin: 1px !important;
    padding: 0;
    word-break: break-all;
  }

  .panel-content-row .meta {
    margin-right: 5px;
  }

  .font-bold {
    font-weight: 700;
  }

  .name-link.has-tooltip {
    .icon {
      display: none;
    }
    span {
      margin-right: 5px;
    }
  }

  .last-comments-theme-title {
    padding: 0;
    float: left;
  }
  .clear {
    margin: 0 !important;
  }
}

.single-comment-box {
  position: relative;
  .panel-content-row {
    .meta {
      margin-right: 5px;
    }
    .fa-remove {
      display: none;
    }
    &:hover .fa-remove {
      display: block;
      float: right;
    }
  }
  .remove-comment-container {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.8);
    width: 100%;
    height: 100%;
    z-index: 1;
    h1 {
      color: #fff;
      text-align: center;
      margin: 0;
    }
    .remove-button-container {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
