.modal-slider {
  background: #0d0d0d;
  cursor: pointer;
  transition: 1s;

  .modal-close-button {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 0;
  }
}

.slider-photo-block {
  display: flex !important;
  height: 90vh;
  margin-top: calc(5vh - 25px);
  &:focus {
    outline: none;
  }
}

.slider-photo {
  max-height: 90vh;
  max-width: 90vw;
  width: auto !important;
  margin: auto;
}

.modal-slider-overlay {
  z-index: 1060;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.arrows-bar {
  background: #000;
  color: #fff;
  height: 50px;
  font-size: 36px;
  display: flex;
  justify-content: space-around;
}

.slick-next {
  top: calc(100% + 25px);
  right: 33%;
}

.slick-prev {
  top: calc(100% + 25px);
  left: 33%;
}

.arrows-bar, .slick-next, .slick-prev {
  transition: 1s;
  margin-top: 100px;
}

.animated-bar {
  .arrows-bar, .slick-next, .slick-prev {
    margin-top: 0px;
  }
}