.summary-table-page {
    .banner-bottom {
        display: none;
        @media (max-width: 500px) {
            display: block !important;
        }
    }

    @media (max-width: 500px) {
        .banner-row .banner {
            &:nth-child(2) {
                display: none;
            }
        }
    }
}
