.collapsible-content {
  display: none;
}
.show-themes {
  display: block;
  .panel-content-row:hover .text {
    text-decoration: underline;
  }
}
.theme-item:last-child .panel-table-header.no-rotate {
  border-radius: 0px 0px 10px 10px;
}
.theme-item .panel-table-header {
  cursor: pointer;
}
.padding-0 {
  padding: 0px !important;
}
.theme-row {
  position: relative;
  button {
    height: 20px;
    font-size: 10px;
    line-height: 20px;
    margin-top: 5px;
  }
  .confirm-delete-theme {
    position: absolute;
    padding: 0;
    left: 0px;
    display: flex;
    top: 0px;
    align-items: initial;
    z-index: 999;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    background: rgba(000, 000, 000, 0.7);
  }
}
.panel-content-row {
  .text {
    cursor: pointer;
    word-break: break-all;
  }
  .number {
    text-align: right;
    padding: 0;
    .fa-remove {
      display: none;
    }
  }
  &:hover .fa-remove {
    display: inline-block;
    cursor: pointer;
  }
}
