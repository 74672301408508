$defaultLeftRightPadding: 15px;
$defaultTopBottomMargin: 10px;
$redIcon: #dd2c00;
$grayIconColor: #cbcfd1;
$hoveredIconColor: #1b6ba8;
$darkFontColor: #323232;
$grayFontColor: #999;

#company-ads-link {
    margin-left: 20px;
}

.item-container {
    border: 1px solid #cbcfd1;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 10px;
    color: $darkFontColor;
    .links-wrapper {
        margin: $defaultTopBottomMargin 0 0 0;
        padding: 3px $defaultLeftRightPadding;
    }
    .item-content {
        .item-container-links-wrapper {
            padding: 5px $defaultLeftRightPadding;
            .icons-links {
                float: right;
                width: 50px;
                height: 100%;
                color: $grayIconColor;
                display: flex;
                justify-content: space-between;
                .fa {
                    font-size: 15px;
                    cursor: pointer;
                    transition-duration: 0.3s;
                    &:hover {
                        color: $hoveredIconColor;
                    }
                }
            }
            h4 {
                // margin: 0 0 10px;
                color: $darkFontColor;
                font-size: 14px;
                font-weight: 500;
                word-wrap: break-word;
                &.transportads-title {
                    margin: 5px 5px 0 0;
                    padding: 0;
                }
            }
        }
        .suplimentar-information {
            margin: 15px 0;
            padding: 0 15px 5px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            .left-panel-info {
                color: $grayFontColor;
                padding-top: 10px;
                .fa-clock-o {
                    margin-left: 15px;
                }
                .clock-time {
                    margin-right: 30px;
                    font-weight: 700;
                }
            }
            .right-links {
                .edit-link {
                    color: $hoveredIconColor;
                    cursor: pointer;
                }
                .delete-link {
                    color: $redIcon;
                    margin-left: 15px;
                    margin-right: 0;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        #ads-item-photos {
            padding: 10px 15px;
            .photo-block {
                max-width: 100%; 
                width: auto; 
                background-repeat: no-repeat !important;
                background-size: cover !important;
                background-position: center !important;
                cursor: pointer;
            }
        }
        .item-container-description {
            margin-bottom: 20px;
            padding: 0 $defaultLeftRightPadding;
            p {
                word-wrap: break-word;
                white-space: pre-wrap;
            }
        }
    }
}
.fa-print {
    padding-top: 2px;
}
.ads-item__ {
    &wrapper {
        .btns-template {
            display: flex;
            justify-content: space-between;
            &::before,
            &::after {
                display: none;
            }
        }
        .not-found-message {
            text-align: center;
        }
    }
}
.timeout-remaining {
    text-align: center;
    font-weight: 700;
    font-size: 17px;
    margin: 30px 0px;
}
