.claim-title-container {
  .color-red {
    text-transform: capitalize;
  }
}

.add-claim-select {
  margin: 10px 0px;
  max-width: 300px;
}
.warning-text,
.add-claim-title {
  margin-bottom: 20px;
}
.company-claims-content .button-orange {
  margin-top: 20px;
}
.add-claim-select-item {
  max-width: 300px;
}
.add-claim-select-item-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10px;
}
.claim-custom-container {
  margin: 5px 0px;
}
.appear-after {
  font-size: 12px;
  color: #36454f;
  margin-top: 10px;
}
.claim-button-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.response-block {
  .secondary {
    margin-right: 10px;
  }
}
.checkbox-container{
  a {
    margin-right: 5px;
  }
}