.shipping-type {
  width: 30% !important;
  // margin-right: 8px;
  height: 65px;
}
.choose-quantity {
  margin-left: 8px;
}

.choose-transport {
  margin-left: 8px;
  width: 40%;
  span {
    display: flex;
    align-items: center;
  }
}

.incoterms {
  width: 260px;
  height: 65px;
  align-items: center !important;
}

.choose-transport-dropdown,
.incoterms-dropdown {
  width: 90% !important;
  margin-right: 8px;
}
.quantity-container {
  display: flex;
  & > div {
    margin-right: 10px;
    position: relative;
  }
  .input-text {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: initial;
    flex-direction: column;
    span {
      color: red;
      position: absolute;
      bottom: -15px;
      width: max-content;
    }
  }
  .quantity-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    flex-direction: column;
    min-width: 110px;
  }
  .validation-message {
    position: absolute;
    margin-top: 0;
    right: -120px;
    top: 7px;
  }
}
