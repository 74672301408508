.messenger {
  height: 80vh;
  margin: 100px auto 30px;
  max-width: 80%;
  display: flex;
    flex-direction: column;

  .scrollable {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar {
    background: white;
    height: 100%;
    overflow: hidden;
    border-right: 1px solid #cbcfd1;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-radius: 0px 0px 0px 10px;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }

  .content {
    background: #fff;
    overflow: hidden;
    height: 100%;
    padding: 0px;
    border-radius: 0px 0px 10px 0px;
  }

  .fa-close {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: #f56709;
    }
  }

  .footer {
    background: white;
  }

  .panel-header {
    width: 100%;
    height: 1px;
    text-align: center;
  }
}

.user-logged_modal-messenger {
  .modal-header {
    height: 35px !important;
  }
}
