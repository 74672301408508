.send-message-popup-container {
  display: block;
  max-width: 40%;
  // min-width: 730px;
  width: 730px;
  height: 75%;
  background: #eeeef1;
  z-index: 99999;
  left: 0px;
  top: 0px;
  position: absolute;
  margin-top: 50px;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, 0);
  border-radius: 10px;
  overflow: hidden;

  .name {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top-container {
    height: 11%;
    z-index: 9;
    position: absolute;
    width: 100%;
  }

  .panel-header h4 {
    flex-grow: 1;
    text-align: left;
  }

  &.mini-messenger {
    height: 90%;
  }
  .button-orange {
    float: right;
  }
  .bottom-container {
    height: 89%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    bottom: 0;
  }

  .spinner {
    min-height: 60%;
  }
  .fa-close {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: #f56709;
    }
  }
  h1 {
    text-align: center;
  }

  .ta-right {
    text-align: right;
    .sm-message {
      float: right;
      background: #f2f6f9;
    }
    .bubble-date {
      right: inherit;
      margin-left: 0px;
      right: 0px !important;
      text-align: right;
      .comment-date-time-container {
        justify-content: flex-end;
      }
      i {
        margin-left: 5px;
      }
    }
  }
  .bubble-date {
    .comment-date-time-container > div {
      margin-left: 0px !important;
    }
    .fa-clock-o {
      margin-left: 5px;
    }
    i {
      margin-right: 5px;
    }
  }
  textarea {
    margin-bottom: 5px;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }
  .error-text {
    color: #ff0000;
  }
  .message-length {
    text-align: right;
    font-weight: 700;
    float: left;
  }
  .bubble-date {
    display: block;
    top: -15px;
    left: 0px;
    text-align: left;
    min-width: 125px;
  }
  .no-content-wrapper {
    width: 100%;
  }
  .sm-message {
    max-width: 70%;
    float: left;
    color: #444648;
    background: #ebeced;
    padding: 10px;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-word;
    border-radius: 10px;
    position: relative;
    white-space: pre-wrap;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);

    .bubble-date {
      color: #a7a9ac;
    }
  }
  .display-block {
    display: block !important;
  }
  table {
    display: block;
    margin: auto;
    width: max-content;

    td {
      border: 1px solid #7f7f7f;
      padding: 5px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .messages-bublles {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #fff;
    padding: 20px 10px 20px 20px;
    margin: 0px;
    border-top: 1px solid #c3d0d8;
    border-bottom: 1px solid #c3d0d8;
    .spinner {
      height: auto;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }

  .message-bubble {
    width: 100%;
    text-align: left;
    word-break: break-all;
    .sm-author {
      font-weight: 700;
      font-size: 16px;
    }
    .sm-date {
      color: #a70000;
      font-weight: 700;
    }
    .clear {
      clear: both;
      margin-bottom: 10px;
    }
  }
  .send-message-container {
    width: 100%;
    padding: 10px;
  }
}
