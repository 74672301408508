.section-theme-title {
  font-weight: 700;
  font-size: 13px;
  cursor: default;
  .clear {
    margin: 0px;
  }
}
.full-tooltip {
  .fa-calendar {
    margin-right: 5px;
  }
}
.single-theme-date {
  float: right;
  cursor: default;
  font-size: 12px;
  text-align: right;
  margin-top: 3px;
  padding: 0;
}
.single-theme-title {
  float: left;
  cursor: default;
  padding: 0;
  font-size: 16px;
}
.name-link:hover {
  text-decoration: underline;
}
.clear {
  clear: both;
}
.panel-comment-desc p,
.panel-message-gray p {
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.move-topic-select {
  width: 30% !important;
  float: right;
  a {
    height: 25px !important;
    line-height: 25px !important;
  }
}
.btn-move-topic {
  min-width: 110px;
  font-size: 13px;
  padding: 0px 15px;
  height: 24px;
  line-height: 20px;
  margin-top: -3px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
}
