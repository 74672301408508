.widget-blacklist {
    .name-lg {
        text-transform: none;
        & > a {
            text-transform: uppercase;
        }
    }
    span {
        margin-right: 5px;
        font-weight: 400;
    }
    .meta {
        margin-right: 15px !important;
    }
    .widget-claim-content {
        a {
            display: block;
            text-align: justify;
        }
    }
}
