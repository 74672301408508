.user_history .text {
  cursor: default;
  overflow-wrap: break-word;
  word-break: normal;
}

.user-history-pagination {
  padding-bottom: 10px;
  .first, .last {
    display: none !important;
  }
}