.full-details {
  .address-line {

  }

  @media screen and (min-width: 991px) {
    max-width: 390px !important;
  }
  .contact-details {
    .phone-number {
      width: 100%;
    }

    @media screen and (min-width: 991px) {
      max-height: 220px;
    }

    div {
      padding-left: 6px;
      div {
        padding-left: 0 !important;
      }
      div,
      input {
        width: 100%;
      }
      height: 65px;
      margin-bottom: 8px;

      @media screen and (max-width: 990px) {
        width: 100%;
      }

      width: 184px;
    }
  }
  label span {
    margin-left: 5px;
  }
}
