.forum-page {
  word-break: break-word;
  white-space: pre-wrap;
  .fl-right {
    margin-top: 0px !important;
  }
  .g-recaptcha{
    margin-bottom: 3px;
  }
  .panel-search-text {
    margin-bottom: 15px;
  }
  @media (max-width: 700px) {
    .ui-pagination .current {
      height: 100%;
    }
  }
  .panel-content-row .number {
    display: flex;
    justify-content: flex-end;
  }

  .show-all-comments-modal {
    height: 25px;
    line-height: 25px !important;
  }
  // i {
  //   margin: 0px 5px;
  // }

  .panel-table-header {
    background: #ebeced;
    margin: 0;
    :last-child() {
      border-radius: 0px 0px 10px 10px;
    }
    .comment {
      font-size: 16px;
      margin: 0;
    }
  }
  .theme-item::before,
  .theme-item::after {
    content: none;
  }
  .panel-content-row::after,
  .panel-content-row::before,
  .panel-body::after,
  .panel-body::before,
  .truck-item-bottom .row::after,
  .truck-item-bottom .row::before,
  .panel-form-row::after,
  .panel-form-row::before {
    content: -moz-alt-content;
  }
  .show-more-details {
    margin: 0px;
    text-align: right;
    font-weight: 700;
  }
  .show-full-comment {
    display: block !important;
  }
  .fa-chevron-up {
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    transform: rotate(0deg);
  }
  .last-comments {
    .fa-chevron-up {
      margin-top: 5px;
      float: right;
    }
    .text {
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      cursor: default;
    }
    .widget-header_wrapper.br-10 {
      border-radius: 10px;
    }
    .rotate {
      transform: rotate(180deg);
      .fa-chevron-up {
        cursor: pointer;
      }
    }
    .widget-header_wrapper h4 {
      display: none;
    }
    .name-link span {
      max-width: 190px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .button-fa-wrapper {
    margin-left: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .last-comments-header {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    @media (max-width: 1319px) {
      .last-comments-title {
        font-size: 12px;
        width: 50%;
      }
    }
  }
  .br-10 {
    border-radius: 10px;
  }
  .newslatter .panel-table-header {
    background: #36454f;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .panel-body {
    border-top: 1px solid #5f7d8b;
  }
  .panel-header {
    padding: 10px 15px !important;
    min-height: 43px;
    .panel-content-row {
      padding: 5px 10px;
    }
    .link-right {
      line-height: inherit !important;
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      .fa-plus {
        position: absolute;
        top: 20%;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
      .button {
        height: 22px;
        margin-bottom: -5px;
      }
      a {
        color: #fff;
        transition: color 0.3s;
        &:hover {
          color: #f56709;
        }
      }
    }
  }
  .theme-title {
    display: block;
    line-height: 15px;
    margin: 5px;
    position: relative;
  }
  .panel-news h6 {
    color: #516674;
    float: none;
  }
  .forum-theme-pagination {
    text-align: right;
    padding: 5px;
  }
  .fa.fa-chevron-up.collapse-close {
    position: absolute;
    right: 0px;
    top: 0px;
    color: #738693;
  }
  .theme-subtitle {
    text-align: left;
    display: block;
    margin-bottom: 10px;
    color: #738693;
  }
  .forum-theme-pagination span {
    padding: 0px 3px;
    &.active {
      font-weight: 700;
      color: #f56709;
      font-size: 14px;
    }
  }

  .search-container-secondary {
    float: left;
    position: relative;
    .fa-close {
      position: absolute;
      right: 0;
      top: 0;
      margin: 9px;
      color: #999;
    }
  }
  .panel-table.newslatter .theme-item:last-child {
    border-radius: 0px 0px 10px 10px;
  }
  .collapse-close.rotate {
    transform: rotate(180deg);
  }
  .help-box .panel-comment-content {
    margin-left: 0px;
  }
  .forum {
    padding-right: 5px;
  }
  .custom-file-upload input[type='file'] {
    display: none;
  }
  .collapsible-content {
    border-bottom: 1px solid #cbcfd1;
  }
  .collapsible-content:last-child {
    border-bottom: none;
  }
  .show-themes .panel-content-row {
    word-break: break-word;
  }
  &:nth-child(2n) {
    background: #f7f7f7;
  }
  .newslatter .collapse-close {
    opacity: 0;
  }

  .pagination-item {
    border: none;
    padding: 3px;
    a {
      padding: 0;
      min-width: 10px;
      height: 10px;
    }
  }
  .ui-pagination {
    .next,
    .prev {
      top: 0px;
    }
  }
  .panel-message-gray {
    padding: 10px;
    border-radius: 6px;
    background-color: rgb(244, 244, 244);
    font-style: italic;
    font-weight: 700;
    margin-top: 5px;
  }
  .panel-message-gray:before {
    top: -14px;
  }
  .for-adaptive {
    display: none;
  }
  @media (max-width: 400px) {
    .panel.newslatter {
      display: none;
      &.for-adaptive {
        display: block !important;
      }
    }
    .ui-pagination .current,
    .ui-pagination .page-style {
      min-width: 15px !important;
      height: 15px;
    }
  }
  .col-md-6 {
    padding: 0;
  }
  .fa.fa-calendar {
    margin-left: 0px;
  }
  .button-orange {
    @media (max-width: 1319px) {
      line-height: normal;
    }
  }
  .last-comments-header  .rotate{
    transform: rotate(180deg);
  }
}
