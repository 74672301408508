@import '../../../../palette/palette.scss';

.auto-park-list_header {
  padding-right: 15px;
  @media (max-width: 767px) and (min-width: 0px) {
    &.panel-header .link-right {
      background: none;
      border: none;
      float: right;
      margin: -13px -15px -12px;
      padding: 10px 5px;
      a {
        color: #fff !important;
      }
    }
  }
}
.autopark-car-list {
  border: none !important;
  box-shadow: none;
  .panel-filter {
    padding: 11px 15px;
    border-bottom: 1px solid #ebeced;
    border-right: 1px solid #cbcfd1;
    border-left: 1px solid #cbcfd1;
  }
  .truck-item {
    border-right: 1px solid #cbcfd1;
    border-left: 1px solid #cbcfd1;
    &:last-child {
      border-bottom: 1px solid #cbcfd1;
      border-radius: 0px 0px 10px 10px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .car-img-container {
      height: 245px;
      margin: 10px 0px;
    }
  }
  @media screen and (max-width: 500px) {
    .panel-header {
      align-items: center;
      flex-direction: column;
      h4 {
        margin-bottom: 5px;
      }
    }
}
}

.car-img-container {
  background-size: contain;
  width: 100%;
  height: 75px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.truck-item-row {
  .col-xs-9 {
    word-break: break-all;
  }
  .label {
    margin-bottom: 4px;
    display: block;
  }
}
.truck-item {
  .name-main {
    u {
      margin-left: 10px;
    }
  }
}
.truck-item-bottom {
  .col-blue {
    i {
      margin-right: 5px;
    }
  }
}

.car-list-container {
  .ui-pagination {
    margin: 20px 0px;
  }
}

.auto-park-list_wrapper {
  h4 {
    font-size: $smallFontSize;
    a {
      font-size: $smallFontSize;
    }
  }

  .auto-park-list_img-container {
    border: 1px solid #000;
  }

  .tab-filter {
    cursor: pointer;
  }
  .table {
    margin: 0;
    .tr-rounded-down {
      > th:first-child,
      > td:first-child {
        border-top-left-radius: 10px;
      }
      > th:last-child,
      > td:last-child {
        border-top-right-radius: 10px;
      }
    }
    .tr-rounded-up {
      > th:first-child,
      > td:first-child {
        border-bottom-left-radius: 10px;
      }
      > th:last-child,
      > td:last-child {
        border-bottom-right-radius: 10px;
      }
    }
    > thead {
      > tr {
        > th {
          border-top: 1px solid #bdc1c3 !important;
          border-color: #bdc1c3;
          padding: 5px;
          text-align: center;
          vertical-align: middle;
          font-weight: 400;
        }
      }
    }
    > tbody {
      > tr {
        &.tr-highlighted {
          > td {
            background-color: #b9d5ea;
          }
        }
        > td {
          padding: 5px;
          vertical-align: middle;
          text-align: center;
          border-width: 1px !important;

          > a {
            padding: 0;
          }

          &.num {
            min-width: 30px;
            text-align: center;
          }

          &.category {
            min-width: 100px;
            text-align: left;
          }
          &.title {
            width: 90%;
            text-align: left;
          }
          &.date-time {
            div {
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .time {
              font-style: italic;
            }
          }
          &.command {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.transport-modal {
  max-height: 90vh;
  max-width: 900px;
  margin: 0 30px;

  @media (max-width: 767px) {
    max-width: 400px;
  }

  .modal-header {
    .close {
      opacity: 1;
      top: 13px;
      z-index: 1;

      span {
        display: block;
        opacity: 1;
        transition: .3s color;

        &:hover {
          color: #f56709;
        }
      }
    }
  }
}
