.employee-block-list-modal {
    max-width: 740px;
    .modal-header {
        display: none !important;
    }
    .fa-times {
        cursor: pointer;
        margin-right: 10px;
    }
    .panel {
        margin-bottom: 0 !important;
    }
    .panel-filter {
        padding-left: 0px;
        border-bottom: 0;
    }
    .empty-filter-data {
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
