@import '../../../../../palette/palette.scss';

.widget-header-menu_wrapper {
  margin: -5px 10px;

  // .dropdown_wrapper {
  //   margin: -5px 10px;

  .dropdown {
    right: 10px;
    padding: 15px;

    .widget-header-menu_item{
      margin-bottom: 5px;

      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
  // }

}