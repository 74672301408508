
.news_item_add-edit_wrapper {
  .label-1 {
    min-width: 80px;
    max-width: 80px;
    display: block;
  }

  .news_item_add-edit_buttons-info {
    flex-grow: 1;
    .asterix{
      color: #dd2c00;
      margin-left: 2px;
    }
  }
}

.news_item_add-edit_image-wrapper{
  position: relative;

  .news_item_add-edit_img-container{
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    max-width: 130px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    
    cursor: pointer;
    img {
      max-width: 130px;
      max-height: 100px;
      border: none;
    }
  }

  .news_item_add-edit_button-delete{
// display:none !important;
    // height: 14px;
    position: absolute;
    color: red;
    // z-index: 3;
    right: 0;
    cursor: pointer;
    padding: 0 1px;
    border-radius: 20px;
    background: #fff;
    line-height: 1em;

    &:hover{
      background: red;
      color: #fff;
    }
  }

  .news_item_add-edit_click-wrapper {
    //.react-fine-uploader-file-input-container {
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // .click-centered {
    // // &.news_item_add-edit_click-centered{
    //   display: flex !important;
    //   height: 100px;
    //   align-items: center;
    //   justify-content: center;
    // }

    .click-wrapper{
    // .react-fine-uploader-file-input-container{
      display: flex !important;
      height: 100px;
      align-items: center;
      justify-content: center;

      >span{
        display: none;
      }
    }

    .news_item_add-edit_click-label-wrapper{
      display: flex;
      padding: 1px;
      opacity: .6;
      background-color: #fff;
      svg{
        margin-right: 2px;
      }
      cursor: pointer;
    }
    .react-fine-uploader-file-input{
      cursor: pointer;
    }
  }

  .react-fine-uploader-delete-button {
    display: none;
  }
}




.news_item_add-edit_image-wrapper {
  position: relative;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;

  .fine-uploader-dropzone-container {
    position: relative;
    border: none;
    min-height: 0;
    max-height: 100px;
    height: 100px;
    width: 130px;
    padding: 0;
    border-radius: 0;

    .news_item_add-edit_click-wrapper {
    //.react-fine-uploader-file-input-container {
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.news_item_add-edit_click-centered{
        display: flex !important;
        height: 100px;
      }

      .news_item_add-edit_click-label-wrapper{
        display: flex;
        padding: 1px;
        opacity: .6;
        background-color: #fff;
        svg{
          margin-right: 2px;
        }
        cursor: pointer;
      }
      .react-fine-uploader-file-input{
        cursor: pointer;
      }
    }

    // .react-fine-uploader-thumbnail-placeholder 
    
    // .react-fine-uploader-thumbnail 

// .react-fine-uploader-gallery-files{
    .react-fine-uploader-thumbnail-container{
      position: absolute;
      padding: 0;
      margin: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border: none;
    
      .react-fine-uploader-thumbnail {
        max-width: 130px;
        max-height: 100px;
      }
      .react-fine-uploader-thumbnail-waiting {
        max-height: 40px;
        max-width: 40px;
      }
    }
  }
  .react-fine-uploader-delete-button {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    padding: 0;
    height: auto;
    border: none;
    font-size: 14px;
  }
}

.news_item_add-edit_langs {
  width: 230px;
  margin-bottom: 0;
}
// .filepond--wrapper,
// .news_item_add-edit_filepond{
//   width: 100%;
//   /* height: 100px; */
//   flex-grow: 1;
// }