@import '../../../palette/palette.scss';

.dropdown_wrapper{

  &.dropdown_disabled{
    // background-color: #ccc;

    .chosen-single{
      background-color: #eee;
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .dropdown_required{
    border-left: 3px solid $fieldIsRequiredColor;
  }
  .dropdown_valid{
    border-left: 3px solid $fieldIsValidColor;
  }
  .dropdown_invalid{
    border-left: 3px solid $fieldIsInvalidColor;
  }

  .delete-button {
    color: black !important;
    &:hover {
      color: #f56709 !important;
    }
  }

  .dropdown-delimiter {
    border-top: 1px solid #cbcfd1;
    &::before {
      content: attr(content);
      color: #323232;
      display: block;
      height: 20px;
    }
  }
}

.result-selected {
  position: relative;
  color: #f56709;

  &::before {
    content: "\f00c";
    position: absolute;
    left: -13px;
    top: 2px;
    font: normal normal normal 11px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 11px;
  }
}