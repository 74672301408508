$search-item-media: 992px;

.cargo-item_wrapper {
  .main-img {
    max-width: 40%;
  }
  .panel-header {
    padding: 15px;
  }
}
.cargo-transport-actions {
  height: auto !important;
  padding: 5px;
  .icon-print {
    cursor: no-drop;
    .fa-print {
      opacity: 0.5;
    }
  }

  .panel-header-menu-item {
    padding: 0 !important;
  }
  .actions-panel a {
    padding-left: 0px !important;
  }
  .fa-calendar {
    margin-left: 10px;
  }
}
.adr-container {
  float: left;
  margin-top: 0px;
  margin-right: 10px;
}
.is-changing-status {
  min-width: 65px;
  cursor: default !important;
  .spinner {
    width: 15px;
    height: 15px;
  }
}
.car-notes {
  margin-right: 20px;
  cursor: pointer;
}
.border-top {
  border-top: 1px solid #cbcfd1;
}
.additional-km {
  .name {
    display: flex;
    margin-top: 8px;
    justify-content: center;

    .city-block {
      display: flex;
    }
  }
  .city-add-km {
    font-size: 12px;
    color: #999;
    line-height: 24px;
    padding-left: 5px;
  }
  .country-abbr {
    margin: 0px 5px;
  }
}

.img-trigger-to-show-car-photo-from-autopark {
  display: none;
}

.cargo-item_spinner {
  margin: 100px 20px;
}

.cargo-item_error {
  color: red;
  margin: 100px 20px;
}
.transport-container {
  width: 100%;
  height: 130px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.cargo-item_main-row {
  display: flex;
  align-items: stretch;
}
.transport-data {
  border-top: 1px solid #cbcfd1;
  padding: 10px 0px;
  .meta {
    display: flex;
  }
  .name {
    display: block;
    margin-top: 2px;
    margin-bottom: 10px;
  }
}
.fl-right {
  margin-top: 20px;
}
.additional-class {
  .fl-right {
    margin-right: 10px;
  }
}
.city-block {
  position: relative;
  max-width: 200px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cargo-item_map {
  width: 43%;
  margin-right: 18px;

  @media screen and (max-width: $search-item-media) {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 50px;
  }

  .map-block {
    height: 100%;
  }

  .map-height {
    min-height: 100% !important;
    @media screen and (max-width: $search-item-media) {
      height: 400px;
    }
  }
}

.cargo-info {
  width: 55%;
  .additional-class,
  .panel-offer-content-row-break {
    padding-bottom: 10px;
  }
  @media screen and (max-width: $search-item-media) {
    width: 100% !important;
  }
}

.cargo-info-modal {
  width: 100% !important;
}

.cargo-item_volumn-wrapper {
  > span {
    margin-left: 10px;
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.panel-timer {
  width: 300px;
  margin: 10px auto 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.panel-header-flex-block {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    align-items: center;
  }
  .meta {
    margin: auto 0;
    top: 0;
  }

  .panel-address {
    max-width: 260px;
    display: inline-block;
    min-width: 160px;

    .address-meta {
      color: #999;
      display: block;
    }
  }
}

.modal-close-button {
  color: #fff;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #f56709;
  }
}

.modal-close-icon {
  right: 18px;
}

.middle-points {
  margin-bottom: 10px;
}

.panel-header-menu {
  width: 100%;
  height: 30px;

  .actions-panel .action-el {
    padding: 0 5px;
  }

  .panel-header-menu-item {
    width: 350px;
    padding-left: 15px;
    padding-right: 5px;
  }
}

.favorite-active-color {
  color: #0db827;
}

.favorite-color {
  color: #f56709;
}
.disabled-styles {
  color: #999 !important;
  text-decoration: none !important;
}
.delivery-container-info {
  display: flex;
}
.delivery-price {
  min-width: 170px;
}
