.cargo-last-search_spinner {
  height: 100%;
}

@media screen and (min-width: 1320px) {
  .search-height {
    .panel-header {
      cursor: default !important;
    }
  }
}

.search-height {
  max-height: 200px;

  .panel-body {
    height: 93%;
  }

  @media screen and (max-width: 1319px) {
    .panel-body {
      height: 163px;
    }
  }

  @media screen and (min-width: 1320px) {
    height: 525px !important;
    max-height: fit-content;
  }

  .panel-header {
    cursor: pointer;
  }

  .fa-chevron-up {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 10px;
    cursor: pointer;

    @media screen and (max-width: 1320px) {
      display: flex;
    }
  }
  .hide-block {
    height: 0px !important;
  }
  .rotate {
    transform: rotate(180deg);
  }
}
