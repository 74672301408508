.change-password-email-modal {
  .icon-close {
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 7px;
    &:hover {
      color: #f56709;
    }
  }
}
