$defaultMargin: 26px;
$mediumScreenSize: 1320px;
$mobileScreenSize: 766px;

.ads-add_wrapper {
    .panel-body {
        padding: 5px 10px;
        b {
            font-weight: 500;
        }
        .select-inputs {
            display: flex;
            margin-bottom: 13px;
            .select-item {
                padding-right: 20px;
                width: 25%;
                @media screen and (max-width: $mediumScreenSize) {
                    width: 31.25%;
                }
                @media screen and (max-width: $mobileScreenSize) {
                    padding-right: 0;
                    width: 100%;
                }
            }
            @media screen and (max-width: $mobileScreenSize) {
                flex-direction: column;
            }
        }
        .photos-container {
            margin-bottom: $defaultMargin;
            .photos {
                display: flex;
                justify-content: space-between;
            }
        }
        .text-container {
            margin-bottom: $defaultMargin;
        }
    }
    .validation-message {
        margin-top: 0;
    }
}
#add {
    min-width: 190px;
}
.add-ads {
    &.btns-template {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            margin-bottom: 0;
        }
    }
}
