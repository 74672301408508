.cargo-add_wrapper {
  .select-prices {
    .row {
      display: flex;
      align-items: center;

      .label {
        margin-bottom: 9px;
        font-weight: normal;
      }
    }
  }

  .cargo-add_tab {
    display: none;
    &.cargo-add_tab-active {
      display: block;
    }
  }

  .cargo-add_adr-class {
    min-width: 40px;
  }

  .cargo-add_temperatures {
    display: inline-block;
    width: 60px !important;
    ul {
      text-align: right;
    }
  }
  .car-select {
    .chosen-drop {
      max-width: 100%;
    }
  }
}

.transport-type-block {
  width: 90%;

  @media screen and (max-width: 550px) {
    width: 100% !important;
  }
}

.ico-placeholder {
  width: 100%;
  height: 60px;
  background-image: url('../../../../images/buse-black.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.ico-truck-buse {
  background-image: url('../../../../images/buse-black.png');
}
.ico-truck-cargo {
  background-image: url('../../../../images/truck-black.png');
}
.ico-truck-tractor {
  background-image: url('../../../../images/teagach-black.png');
}
.ico-truck-cargo-lg {
  background-image: url('../../../../images/truck-lg-black.png');
}

.transport-icon-block {
  width: 11%;
  position: relative;
  top: 8px;
  
  @media screen and (max-width: 550px) {
    top: 0px !important;
    width: 100% !important;
  }
}
.car-type,
.ownership-type {
  width: 41%;

  @media screen and (max-width: 550px) {
    width: 100% !important;
  }
}

.cargo-add_calendar-selector {
  position: relative;

  .cargo-add_calendar-wrapper {
    position: absolute;
    top: 20px;
    z-index: 9;
  }
}
