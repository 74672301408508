.ck-editor {
  flex-grow: 1;

  .ck-editor__main {
    >.ck-editor__editable{
      min-height: 100px;
      height: 300px;
    }
  }
}
.ck{
  .ck-balloon-panel {
    &.ck-balloon-panel_visible {
      z-index: 2000;
    }
  }
}

.ck-heading_heading1 {
  font-size: 20px;
}
.ck-heading_heading2 {
  font-size: 18px;
}
.ck-heading_heading3 {
  font-size: 16px;
}
.ck-heading_heading4 {
  font-size: 15px;
}




// .editor_wrapper {
//     min-height: 100px;
//     height: 300px;
//     // overflow: hidden;

//     flex-grow: 1;
//     display: flex;
//     flex-direction: column;

//     background: #fff;
//     border: 1px solid #cbcfd1;
//     border-radius: 5px;
//     padding: 0;
//     line-height: 1em;
//     color: #323232;
//     font-family: "Roboto", sans-serif;
//     // font-smoothing: antialiased;
//     font-size: 12px;

//     img{
//       border: 2px solid transparent;
//       &:active,
//       &:focus,
//       &:focus-within{
//         border: 2px solid aliceblue;
//       }
//     }
// }

// .editor_toolbar {
//   flex-shrink: 0;
//   display: flex;
//   padding: 0; 
//   //5px;
//   background: #f7f7f7;
//   // border-bottom: 1px solid #cbcfd1;

//   .editor_toolbar-button-wrapper {

//   }
// }


// .editor_body-wrapper{
//   flex-grow: 1;
//   padding: 5px;
//   // background: yellow;
//   display: flex;
// }

// .editor_body {
//   overflow: auto;
//   flex-grow: 1;

//   background: #fff;
//   // border: 1px solid #cbcfd1;
//   // border-radius: 5px;
//   padding: 6px;
//   line-height: 1em;
//   color: #323232;
//   font-family: "Roboto", sans-serif;
//   // font-smoothing: antialiased;
//   font-size: 12px;
// }


// blockquote {
//   font-size: 1.2em;
//   padding: 10px 15px !important;
//   color: #aaa;
//   font-style: italic;
// }





blockquote {
  position: relative;  /*  <--- */
  // background-color: #f9f9f9;
  border: none !important; // 5px solid #ccc;
  // margin: 1.5em 10px;
  // padding: 0.5em 10px;
  padding: 10px !important;
  color: #555;
  // #aaa;
  font-size: 1.2em;
  font-style: italic;
  // quotes: "\201C""\201D""\2018""\2019";

  >h1:first-child,
  >h2:first-child,
  >h3:first-child,
  >p:first-child {
    display: inline;
  }
  // p:last-child { margin-bottom: 0; }

  &:before,
  &:after {
    font-family: 'FontAwesome';
    position: absolute;
    position: relative;
    line-height: 0.1em;
    /* -- inside the relative position of blockquote -- */
    // top: 13px;
    // color: #aaa;
    color: #ddd;
    // color: rgba(0, 0, 0, 0.1);
    font-size: 20px;

    // color: #ccc;
    // content: open-quote;
    // font-size: 3em;
    // line-height: 0.1em;
    // // margin-right: 0.1em;
    // vertical-align: -0.4em;
    // position: relative;;
  }
  &:before{
    content: "\f10d";
    margin-right: 10px;
    // right: 100%;
    // top: -2px;
    // left: -10px;
  }
  &:after{
    content: "\f10e";
    margin-left: 10px;
    bottom: -8px;
    // left: 100%;  
    // right: 10px;
    // bottom: 0;
    // top: 100%;
  }
  

  // &:before {
  //   color: #ccc;
  //   content: open-quote;
  //   font-size: 3em;
  //   line-height: 0.1em;
  //   // margin-right: 0.1em;
  //   vertical-align: -0.4em;
  //   position: relative;;
  //   top: -2px;
  //   left: -10px;
  // }
  // &:after {
  //   color: #ccc;
  //   content: close-quote;
  //   font-size: 3em;
  //   line-height: 0.1em;
  //   // margin-right: 0.1em;
  //   vertical-align: -0.4em;
  //   position: relative;;
  //   // top: -2px;
  //   // left: -10px;
  // }
 
}





//  blockquote {
//   border-radius: 3px;
//   position: relative;  /*  <--- */
//   font-style: italic;
//   //text-align: center;
//   padding: 1rem 1.2rem;
//   // width: 80%;  /* create space for the quotes */
//   color: #4a4a4a;
//   margin: 1rem auto 2rem;
//   color: #4a4a4a;
//   background: #E8E8E8;
// }
// /* -- create the quotation marks -- */
//  blockquote:before,
//  blockquote:after{
//   font-family: FontAwesome;
//   position: absolute;
//   /* -- inside the relative position of blockquote -- */
//   top: 13px;
//   color: #aaa;
//   font-size: 34px;
// }
//  blockquote:before{
//   content: "\f10d";
//   margin-right: 13px;
//   right: 100%;
// }
//  blockquote:after{
//   content: "\f10e";
//   margin-left: 13px;
//   left: 100%;  
// }





// .text-align-left { text-align: left; }
// .text-align-right { text-align: right; }
// .text-align-center { text-align: center; }
// .text-align-justify { text-align: justify; }