.popup-overlay.show {
  display: block;
}
.panel-content-row .popup-overlay {
  display: block;
}
.error {
  border: 2px solid #ff0000 !important;
}
.error-length {
  color: #ff0000;
  font-weight: 700;
}
.remove-padding {
  padding: 0px;
}
.popup-truck .col-md-12 {
  width: 50%;
  .panel-content-row,
  .panel-body,
  .panel {
    border-top: none;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}
.sections-list,
.add-theme-select {
  cursor: pointer;
}
.popup-truck {
  .panel-content-row,
  .panel-body,
  .panel {
    border-top: none;
    border-radius: 10px;
    cursor: default;
  }
  .panel-body {
    border-top: 1px solid #cbcfd1 !important;
  }
}
.add-theme-select {
  text-transform: uppercase;
}
.empty-themes-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.add-theme-modal input[type="text"], textarea {
  &::placeholder {
    color: #757575;
  }
}
