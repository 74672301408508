.prices-page {
    .style-1 {
        border-bottom: 1px solid #cbcfd1;
    }
    .style-2 {
        text-transform: unset !important;
        border-bottom: 1px solid #cbcfd1;
    }
    .style-3 {
        background-color: #f6b68c !important;
        border-bottom: 1px solid #cbcfd1;
    }
    .style-light {
        background-color: #f5d5c1;
        border-bottom: 1px solid #cbcfd1;
    }
    .padding-3-20 {
        padding: 3px 20px;
    }
    .style-4 {
        background-color: #f6b68c !important;
    }
    .style-4-1 {
        background-color: #f5d5c1 !important;
    }
    .style-5 {
        background-color: #f5d5c1 !important;
    }
    .text-bold {
        font-weight: 700;
        color: #323232 !important;
    }
    .table-striped th,
    .table-striped td {
        text-align: center;
    }
    .th-additional-styles {
        text-align: center;
        align-items: center;
        justify-content: center;
        min-height: 100px;
        text-transform: none !important;
    }
    .table td {
        text-align: center;
        padding: 10px;
    }
    .table td:first-child {
        text-align: left !important;
    }

    .table th {
        vertical-align: middle !important;
        padding: 5px;
    }
    .panel {
        border-bottom: 0px;
        border-radius: 0;
        margin-top: 20px;
    }
    @media (max-width: 500px) {
        .panel{
            overflow-x: auto;
            display: grid;
            text-align: center;
            &:nth-of-type(1){
                text-align: left;
            }
        }
    }

    .table {
        margin-bottom: 0px;
    }
    .panel-table-collapsible {
        overflow-x: auto;
    }
}
