.review-answer-date {
  display: flex !important;
  float: right;
  margin: 0px 0px 5px 10px;
}

.review-asnwer {
  padding: 10px;
  border-radius: 6px;
  background-color: #f4f4f4;
}

.claim-content {
  position: relative;
  .remove-container {
    width: 420px;
  }
  .panel-message-content,
  .review-asnwer {
    word-break: break-all;
  }
  .link-message {
    margin-left: 20px;
  }
  .panel-message-header {
    .name {
      margin-right: 10px;
    }
  }
}
.remove-m-b {
  margin-bottom: 0px !important;
  .link-message {
    margin-top: 5px;
  }
}
.claim-answer {
  position: absolute;
  top: -20px;
}
.delete-claim-container {
  display: flex;
  justify-content: flex-end;
  // margin-bottom: -15px;
  color: #999;
  span {
    margin-left: 5px;
  }
}
