.manufacturer {
  width: 60%;

  @media screen and (max-width: 550px) {
    width: 100% !important;
  }
}

.registration-number,
.eco-standard {
  width: 38%;

  @media screen and (max-width: 550px) {
    width: 100% !important;
  }
}
.hide-field {
  display: none;
}
.body-type,
.prod-year {
  width: 29%;
  margin-right: 10px;
  @media screen and (max-width: 550px) {
    width: 49% !important;
  }
}
