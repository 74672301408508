.admin-notice-panel {
  background: #ec6451;
  border-radius: 10px;
  color: #fff;
  padding: 15px 20px;
  margin-bottom: 20px;
  word-wrap: break-word;

  h4 {
    text-transform: uppercase;
    margin-top: 0;
    font-size: 16px;
  }

  .text {
    font-weight: 500;
    white-space: pre-wrap;
  }
}