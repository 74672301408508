.news-add-comment_wrapper {
  .news-add-comment_info-row {
    display: flex;
    label {
      flex-grow: 1;
    }
  }

  .img-container {
    margin-top: 0;
    img {
      object-fit: cover;
      width: 100%;
      height: 100% !important;
    }
  }
  .add-comment-top-form {
    display: flex;
    flex-direction: column;
  }
}
.news-comment-panel {
  .panel-comment .panel-comment-desc p {
    padding: 0px;
  }
  .panel-comment-content {
    margin-left: 0px !important;
  }
}
