
.auth-login_wrapper {
  .form-group {
    padding-bottom: 5px;
    margin: 0;
    min-height: 85px;
    
    .form-control {
      margin-bottom: 10px;
    }

    .password-control {
      right: 10px;
    }
  }

  .form-row-buttons {
    margin-top: 0;
  }
}