.auth-registration-success_wrapper {
  min-height: 200px;
  padding: 20px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .panel-content{
    text-align: center;
  }
}
