.compose {
  padding: 0px 20px 0px 20px;
  position: absolute;
  bottom: 20px;
  width: 100%;
  background: #fff;

  .button {
    float: right;
    margin-top: 15px;
  }

  .toolbar-button {
    color: #bbbbbf;
    margin-left: 15px;
  }

  .toolbar-button:hover {
    color: #99999c;
  }

  .error-text{
    color: #ff0000;
  }

  .message-length{
    padding-top: 15px;
    text-align: right;
    font-weight: 700;
    float: left;
  }

  .compose-textarea {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    font-size: 14px;
    height: 40px;
    background: none;
    min-height: 70px;
  
    &::placeholder {
      opacity: 0.3;
    }
    
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #323232;
      opacity: 0.3; /* Firefox */
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }

}
