.temperatures-from-to_wrapper {
  .temperatures-from-to_temperature {
    display: inline-block;
    width: 58px !important;
    ul {
      text-align: right;
    }
  }
  .select-wrapper {
    align-items: baseline;
  }
  &.input-toggle-select input[type="checkbox"]:checked + label + .select-wrapper {
    display: flex;
  }
}
