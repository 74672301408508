.switch_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  // .switch_item {
  //   position: relative;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;

  $switch-width: 27px;
  $bullet-size: 15px;
  .switch_radio {
    width: $switch-width;
    max-width: $switch-width;
    min-width: $switch-width;
    height: $bullet-size;
    max-height: $bullet-size;
    min-height: $bullet-size;
    position: relative;
    border-radius: $switch-width;
    // border: 2px solid #4d4e53;
    background-color: #919296;
    border: 1px solid #aaa;
    //  #919296;
    transition: all 0.3s ease-in-out !important;
    padding: 0;
    cursor: pointer;

    &.switch_on {
      background-color: #46b8da;
      border-color: #46b8da;

      .switch_radio-bullet {
        border-color: #46b8da;
        left: $switch-width - $bullet-size;
      }
    }

    .switch_radio-bullet {
      width: $bullet-size;
      height: $bullet-size;

      background-color: #fff;
      transition: all 0.3s ease-in-out !important;
      display: inline-block !important;
      position: absolute;
      left: -2px;
      top: -1px;

      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
      border: 1px solid #aaa;
      // #919296;
      border-radius: $bullet-size;
    }
  }

  .switch_label {
    margin-left: 4px;
  }

  &.switch_text-bullet {
    .switch_label {
      margin-left: 0;
      margin-right: 4px;
    }
  }
  // }

  // .switch_
  // .choices-inline .choice-item .choice-wrapper {
  //   background-color: #919296;
  //   border: 1px solid #919296;
  // }
}
