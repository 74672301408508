@import '../../../palette/palette.scss';

.panel-content-table {
  border-top: none;
}

.cargo-search-results_tr-header {
  > td {
    background-color: #36454f !important;
    color: #ffffff;
    border-bottom: 1px solid #36454f;
    border-top: none !important;
    vertical-align: middle !important;

    &:first-of-type {
      border-left-color: #36454f;
    }
    &:last-of-type {
      border-right-color: #36454f;
    }
  }
}

.cargo-search-results_modal {
  min-width: 60%;
  position: absolute;
  top: 20px;
}

.company-users {
  max-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 70%;
}
.label-items-found {
  font-size: 16px;
}

.panel-table-lg {
  box-shadow: none;
}

.my-offers {
  border: none;
  box-shadow: none;

  .panel-header {
    margin: 0;
  }

  .panel-filter {
    border-radius: 0;
    border-top: none;
    border-bottom: 2px solid #bdc1c3;
    &:nth-child(2) {
      @media screen and (max-width: 991px) {
        border-bottom: 2px solid #cbcfd1;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .fa-trash {
    color: #dd2c00;
  }
}
