.all-comments-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.6);
  top: 0px;
  left: 0px;
  z-index: 1059;

  .panel-header {
    z-index: 99;
  }
  .all-comments-modal-overlay-to-close {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .last-comments-theme-title {
    float: left;
    line-height: 15px;
  }
  .clear {
    margin: 0;
  }
  .all-comments-modal-content {
    width: 90%;
    height: 90%;
    background: #fff;
    position: relative;
    margin: 3% auto;
    left: 0;
    right: 0;
    border-radius: 10px;
    .name-link {
      margin-right: 10px;
    }
    .show-more-details {
      display: none;
    }
  }
  .last-comments-theme-wrapper {
    display: flex;
  }
  .all-comments-content {
    overflow-y: scroll;
    height: calc(100% - 50px);
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: transparent;
    }
  
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }
  .icon-close {
    width: auto;
    height: auto;
    line-height: inherit;
    font-size: 18px;
    margin: 5px;
  }
  .spinner {
    margin: 30%;
    position: absolute;
    left: 0;
    right: 0;
  }
  .all-comment-pagination {
    padding: 10px;
  }
  .last-comments-theme {
    float: left;
    line-height: 15px;
    margin-right: 5px;
  }
  .theme-title-all-comments {
    margin: 0px !important;
    word-break: break-all;
    cursor: pointer;
    font-weight: 700;
  }
  .text {
    cursor: default;
  }
}
