.company-edit_wrapper {
}
.company-edit_modal {
  max-width: 590px;

  > .modal-content {
    overflow: hidden;

    > .modal-header {
      background: #921d00;
      padding: 10px 15px;
      color: #fff;

      > .modal-title {
        text-align: left !important;
        font-weight: normal !important;
      }
      > .close {
        color: #fff;
        opacity: 1;
        transition: color 0.3s;

        &:hover {
          color: #f56709;
        }
      }
    }
  }
}

.full-width {
  width: 100%;
}

.button-style {
  width: 140px;
  position: relative;
  bottom: 14px;
}
