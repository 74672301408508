.conversation-search {
  padding: 10px;
  display: flex;
/*  position: absolute; */
  width: 100%;
  background: #fff;
}

.conversation-search-input {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  
  &::placeholder {
    text-align: center;
  }
  &:focus::placeholder {
    text-align: left;
  }
}