.not-ready-yet_wrapper {

  .not-ready-yet_message{
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #999;
    font-size: 14px;
    text-transform: uppercase;
  } 
}