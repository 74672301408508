.label,
label {
  color: unset; }

.popover.bs-popover-top > .arrow {
  bottom: calc((0.8rem + 1px) * -1); }

.popover.bs-popover-bottom > .arrow {
  top: calc((0.8rem + 1px) * -1); }

.popover > .arrow {
  border-width: 3px; }

input[type="number"] {
  padding-right: 1px; }

.input-text .mark {
  padding: 0 8px 0 0;
  background-color: transparent; }

input[type="checkbox"] + label + .select-wrapper,
input[type="checkbox"] + label + .has-tooltip + .select-wrapper {
  display: none; }

input[type="checkbox"]:checked + label + .select-wrapper,
input[type="checkbox"]:checked + label + .has-tooltip + .select-wrapper {
  display: inline-block; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }
  .animated.bounceIn {
    animation-duration: .7s; }
  .animated.bounceOut {
    animation-duration: .5s; }
  .animated.bounceIn {
    animation-name: bounceIn; }
  .animated.bounceOut {
    animation-name: bounceOut; }
  .animated.fadeIn {
    animation-name: fadeIn;
    animation-duration: .7s; }
  .animated.fadeOut {
    animation-name: fadeOut;
    animation-duration: .3s; }
  .animated.bounceInDown {
    animation-name: bounceInDown; }
  .animated.bounceOutUp {
    animation-name: bounceOutUp; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: perspective(1px) scale3d(1, 1, 1); } }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }
  .rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8); }
  .rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: white;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden; }
    .rrt-confirm-holder .rrt-confirm .rrt-message {
      width: 100%;
      padding: 5%;
      min-height: 50px;
      font-size: 1em;
      background-color: white;
      text-align: center;
      font-family: 'open-sanscondensed-light', sans-serif;
      clear: both; }
    .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
      display: flex; }
      .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
        flex-grow: 1;
        height: 50px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        float: left;
        border-top: 1px solid #f0f0f0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
          background-color: #f5f5f5; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
          background-color: #60bb71;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
          background-color: #db6a64;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
          outline: none; }

body.toastr-confirm-active {
  overflow: hidden; }

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0; }

.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0; }

.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0; }

.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0; }

.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px; }

@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px; }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -160px; } }

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: .94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); }
  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    opacity: 1; }
  .redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px; }
    .redux-toastr .toastr .toastr-status.success {
      background-color: #60bb71; }
    .redux-toastr .toastr .toastr-status.warning {
      background-color: #f7a336; }
    .redux-toastr .toastr .toastr-status.info {
      background-color: #58abc3; }
    .redux-toastr .toastr .toastr-status.error {
      background-color: #db6a64; }
  .redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden; }
  .redux-toastr .toastr .rrt-left-container {
    width: 80px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0; }
    .redux-toastr .toastr .rrt-left-container .rrt-holder {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
      left: 5px;
      line-height: 60px; }
    .redux-toastr .toastr .rrt-left-container .toastr-icon {
      fill: white;
      vertical-align: middle;
      margin-top: 5px; }
  .redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 80px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px; }
    .redux-toastr .toastr .rrt-middle-container .rrt-title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px; }
  .redux-toastr .toastr .rrt-right-container {
    width: 10%; }
  .redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    font-size: 22px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial sans-serif; }
    .redux-toastr .toastr .close-toastr:hover {
      opacity: 1; }
    .redux-toastr .toastr .close-toastr:focus {
      outline: none; }
  .redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
    color: white; }
  .redux-toastr .toastr.rrt-info {
    background-color: #58abc3; }
    .redux-toastr .toastr.rrt-info .rrt-progressbar {
      background-color: #378298; }
  .redux-toastr .toastr.rrt-success {
    background-color: #60bb71; }
    .redux-toastr .toastr.rrt-success .rrt-progressbar {
      background-color: #3e914d; }
  .redux-toastr .toastr.rrt-warning {
    background-color: #f7a336; }
    .redux-toastr .toastr.rrt-warning .rrt-progressbar {
      background-color: #d87e09; }
  .redux-toastr .toastr.rrt-error {
    background-color: #db6a64; }
    .redux-toastr .toastr.rrt-error .rrt-progressbar {
      background-color: #c5352e; }
  .redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc; }
  .redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important; }
  .redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb; }
    .redux-toastr .toastr.rrt-message .rrt-title {
      width: 90%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 50px;
      padding: 0 20px; }
    .redux-toastr .toastr.rrt-message .rrt-text {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      background-color: white;
      padding: 15px;
      font-size: 1.1em;
      margin-bottom: 20px; }
      .redux-toastr .toastr.rrt-message .rrt-text img {
        display: block;
        margin: 10px auto;
        max-width: 100%; }
    .redux-toastr .toastr.rrt-message .close-toastr {
      height: 50px; }
  .redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
      -webkit-border-radius: 0 0 0 4px;
      -moz-border-radius: 0 0 0 4px;
      border-radius: 0 0 0 4px;
      height: 100%; }

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 8px; }

@media (min-width: 768px) {
  .container {
    width: 736px; } }

@media (min-width: 992px) {
  .container {
    width: 956px; } }

@media (min-width: 1080px) {
  .container {
    width: 1044px; } }

@media (min-width: 1320px) {
  .container {
    width: 1280px;
    max-width: 100%; } }

@media (min-width: 1320px) {
  .container {
    width: 1280px; }
  .row-custom-main .col-sm-7 {
    width: 42%; }
  .row-custom-main .col-sm-9 {
    width: 58%; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 8px; }

.row {
  margin-left: -8px;
  margin-right: -8px; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xl-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xl-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xl-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xl-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12, .col-xs-13, .col-sm-13, .col-md-13, .col-lg-13, .col-xl-13, .col-xs-14, .col-sm-14, .col-md-14, .col-lg-14, .col-xl-14, .col-xs-15, .col-sm-15, .col-md-15, .col-lg-15, .col-xl-15, .col-xs-16, .col-sm-16, .col-md-16, .col-lg-16, .col-xl-16 {
  position: relative;
  min-height: 1px;
  padding-left: 8px;
  padding-right: 8px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16 {
  float: left; }

.col-xs-16 {
  width: 100%; }

.col-xs-15 {
  width: 93.75%; }

.col-xs-14 {
  width: 87.5%; }

.col-xs-13 {
  width: 81.25%; }

.col-xs-12 {
  width: 75%; }

.col-xs-11 {
  width: 68.75%; }

.col-xs-10 {
  width: 62.5%; }

.col-xs-9 {
  width: 56.25%; }

.col-xs-8 {
  width: 50%; }

.col-xs-7 {
  width: 43.75%; }

.col-xs-6 {
  width: 37.5%; }

.col-xs-5 {
  width: 31.25%; }

.col-xs-4 {
  width: 25%; }

.col-xs-3 {
  width: 18.75%; }

.col-xs-2 {
  width: 12.5%; }

.col-xs-1 {
  width: 6.25%; }

.col-xs-pull-16 {
  right: 100%; }

.col-xs-pull-15 {
  right: 93.75%; }

.col-xs-pull-14 {
  right: 87.5%; }

.col-xs-pull-13 {
  right: 81.25%; }

.col-xs-pull-12 {
  right: 75%; }

.col-xs-pull-11 {
  right: 68.75%; }

.col-xs-pull-10 {
  right: 62.5%; }

.col-xs-pull-9 {
  right: 56.25%; }

.col-xs-pull-8 {
  right: 50%; }

.col-xs-pull-7 {
  right: 43.75%; }

.col-xs-pull-6 {
  right: 37.5%; }

.col-xs-pull-5 {
  right: 31.25%; }

.col-xs-pull-4 {
  right: 25%; }

.col-xs-pull-3 {
  right: 18.75%; }

.col-xs-pull-2 {
  right: 12.5%; }

.col-xs-pull-1 {
  right: 6.25%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-16 {
  left: 100%; }

.col-xs-push-15 {
  left: 93.75%; }

.col-xs-push-14 {
  left: 87.5%; }

.col-xs-push-13 {
  left: 81.25%; }

.col-xs-push-12 {
  left: 75%; }

.col-xs-push-11 {
  left: 68.75%; }

.col-xs-push-10 {
  left: 62.5%; }

.col-xs-push-9 {
  left: 56.25%; }

.col-xs-push-8 {
  left: 50%; }

.col-xs-push-7 {
  left: 43.75%; }

.col-xs-push-6 {
  left: 37.5%; }

.col-xs-push-5 {
  left: 31.25%; }

.col-xs-push-4 {
  left: 25%; }

.col-xs-push-3 {
  left: 18.75%; }

.col-xs-push-2 {
  left: 12.5%; }

.col-xs-push-1 {
  left: 6.25%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-16 {
  margin-left: 100%; }

.col-xs-offset-15 {
  margin-left: 93.75%; }

.col-xs-offset-14 {
  margin-left: 87.5%; }

.col-xs-offset-13 {
  margin-left: 81.25%; }

.col-xs-offset-12 {
  margin-left: 75%; }

.col-xs-offset-11 {
  margin-left: 68.75%; }

.col-xs-offset-10 {
  margin-left: 62.5%; }

.col-xs-offset-9 {
  margin-left: 56.25%; }

.col-xs-offset-8 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 43.75%; }

.col-xs-offset-6 {
  margin-left: 37.5%; }

.col-xs-offset-5 {
  margin-left: 31.25%; }

.col-xs-offset-4 {
  margin-left: 25%; }

.col-xs-offset-3 {
  margin-left: 18.75%; }

.col-xs-offset-2 {
  margin-left: 12.5%; }

.col-xs-offset-1 {
  margin-left: 6.25%; }

.col-xs-offset-0 {
  margin-left: 0%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16 {
    float: left; }
  .col-sm-16 {
    width: 100%; }
  .col-sm-15 {
    width: 93.75%; }
  .col-sm-14 {
    width: 87.5%; }
  .col-sm-13 {
    width: 81.25%; }
  .col-sm-12 {
    width: 75%; }
  .col-sm-11 {
    width: 68.75%; }
  .col-sm-10 {
    width: 62.5%; }
  .col-sm-9 {
    width: 56.25%; }
  .col-sm-8 {
    width: 50%; }
  .col-sm-7 {
    width: 43.75%; }
  .col-sm-6 {
    width: 37.5%; }
  .col-sm-5 {
    width: 31.25%; }
  .col-sm-4 {
    width: 25%; }
  .col-sm-3 {
    width: 18.75%; }
  .col-sm-2 {
    width: 12.5%; }
  .col-sm-1 {
    width: 6.25%; }
  .col-sm-pull-16 {
    right: 100%; }
  .col-sm-pull-15 {
    right: 93.75%; }
  .col-sm-pull-14 {
    right: 87.5%; }
  .col-sm-pull-13 {
    right: 81.25%; }
  .col-sm-pull-12 {
    right: 75%; }
  .col-sm-pull-11 {
    right: 68.75%; }
  .col-sm-pull-10 {
    right: 62.5%; }
  .col-sm-pull-9 {
    right: 56.25%; }
  .col-sm-pull-8 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 43.75%; }
  .col-sm-pull-6 {
    right: 37.5%; }
  .col-sm-pull-5 {
    right: 31.25%; }
  .col-sm-pull-4 {
    right: 25%; }
  .col-sm-pull-3 {
    right: 18.75%; }
  .col-sm-pull-2 {
    right: 12.5%; }
  .col-sm-pull-1 {
    right: 6.25%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-16 {
    left: 100%; }
  .col-sm-push-15 {
    left: 93.75%; }
  .col-sm-push-14 {
    left: 87.5%; }
  .col-sm-push-13 {
    left: 81.25%; }
  .col-sm-push-12 {
    left: 75%; }
  .col-sm-push-11 {
    left: 68.75%; }
  .col-sm-push-10 {
    left: 62.5%; }
  .col-sm-push-9 {
    left: 56.25%; }
  .col-sm-push-8 {
    left: 50%; }
  .col-sm-push-7 {
    left: 43.75%; }
  .col-sm-push-6 {
    left: 37.5%; }
  .col-sm-push-5 {
    left: 31.25%; }
  .col-sm-push-4 {
    left: 25%; }
  .col-sm-push-3 {
    left: 18.75%; }
  .col-sm-push-2 {
    left: 12.5%; }
  .col-sm-push-1 {
    left: 6.25%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-16 {
    margin-left: 100%; }
  .col-sm-offset-15 {
    margin-left: 93.75%; }
  .col-sm-offset-14 {
    margin-left: 87.5%; }
  .col-sm-offset-13 {
    margin-left: 81.25%; }
  .col-sm-offset-12 {
    margin-left: 75%; }
  .col-sm-offset-11 {
    margin-left: 68.75%; }
  .col-sm-offset-10 {
    margin-left: 62.5%; }
  .col-sm-offset-9 {
    margin-left: 56.25%; }
  .col-sm-offset-8 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 43.75%; }
  .col-sm-offset-6 {
    margin-left: 37.5%; }
  .col-sm-offset-5 {
    margin-left: 31.25%; }
  .col-sm-offset-4 {
    margin-left: 25%; }
  .col-sm-offset-3 {
    margin-left: 18.75%; }
  .col-sm-offset-2 {
    margin-left: 12.5%; }
  .col-sm-offset-1 {
    margin-left: 6.25%; }
  .col-sm-offset-0 {
    margin-left: 0%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16 {
    float: left; }
  .col-md-16 {
    width: 100%; }
  .col-md-15 {
    width: 93.75%; }
  .col-md-14 {
    width: 87.5%; }
  .col-md-13 {
    width: 81.25%; }
  .col-md-12 {
    width: 75%; }
  .col-md-11 {
    width: 68.75%; }
  .col-md-10 {
    width: 62.5%; }
  .col-md-9 {
    width: 56.25%; }
  .col-md-8 {
    width: 50%; }
  .col-md-7 {
    width: 43.75%; }
  .col-md-6 {
    width: 37.5%; }
  .col-md-5 {
    width: 31.25%; }
  .col-md-4 {
    width: 25%; }
  .col-md-3 {
    width: 18.75%; }
  .col-md-2 {
    width: 12.5%; }
  .col-md-1 {
    width: 6.25%; }
  .col-md-pull-16 {
    right: 100%; }
  .col-md-pull-15 {
    right: 93.75%; }
  .col-md-pull-14 {
    right: 87.5%; }
  .col-md-pull-13 {
    right: 81.25%; }
  .col-md-pull-12 {
    right: 75%; }
  .col-md-pull-11 {
    right: 68.75%; }
  .col-md-pull-10 {
    right: 62.5%; }
  .col-md-pull-9 {
    right: 56.25%; }
  .col-md-pull-8 {
    right: 50%; }
  .col-md-pull-7 {
    right: 43.75%; }
  .col-md-pull-6 {
    right: 37.5%; }
  .col-md-pull-5 {
    right: 31.25%; }
  .col-md-pull-4 {
    right: 25%; }
  .col-md-pull-3 {
    right: 18.75%; }
  .col-md-pull-2 {
    right: 12.5%; }
  .col-md-pull-1 {
    right: 6.25%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-16 {
    left: 100%; }
  .col-md-push-15 {
    left: 93.75%; }
  .col-md-push-14 {
    left: 87.5%; }
  .col-md-push-13 {
    left: 81.25%; }
  .col-md-push-12 {
    left: 75%; }
  .col-md-push-11 {
    left: 68.75%; }
  .col-md-push-10 {
    left: 62.5%; }
  .col-md-push-9 {
    left: 56.25%; }
  .col-md-push-8 {
    left: 50%; }
  .col-md-push-7 {
    left: 43.75%; }
  .col-md-push-6 {
    left: 37.5%; }
  .col-md-push-5 {
    left: 31.25%; }
  .col-md-push-4 {
    left: 25%; }
  .col-md-push-3 {
    left: 18.75%; }
  .col-md-push-2 {
    left: 12.5%; }
  .col-md-push-1 {
    left: 6.25%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-16 {
    margin-left: 100%; }
  .col-md-offset-15 {
    margin-left: 93.75%; }
  .col-md-offset-14 {
    margin-left: 87.5%; }
  .col-md-offset-13 {
    margin-left: 81.25%; }
  .col-md-offset-12 {
    margin-left: 75%; }
  .col-md-offset-11 {
    margin-left: 68.75%; }
  .col-md-offset-10 {
    margin-left: 62.5%; }
  .col-md-offset-9 {
    margin-left: 56.25%; }
  .col-md-offset-8 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 43.75%; }
  .col-md-offset-6 {
    margin-left: 37.5%; }
  .col-md-offset-5 {
    margin-left: 31.25%; }
  .col-md-offset-4 {
    margin-left: 25%; }
  .col-md-offset-3 {
    margin-left: 18.75%; }
  .col-md-offset-2 {
    margin-left: 12.5%; }
  .col-md-offset-1 {
    margin-left: 6.25%; }
  .col-md-offset-0 {
    margin-left: 0%; } }

@media (min-width: 1080px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16 {
    float: left; }
  .col-lg-16 {
    width: 100%; }
  .col-lg-15 {
    width: 93.75%; }
  .col-lg-14 {
    width: 87.5%; }
  .col-lg-13 {
    width: 81.25%; }
  .col-lg-12 {
    width: 75%; }
  .col-lg-11 {
    width: 68.75%; }
  .col-lg-10 {
    width: 62.5%; }
  .col-lg-9 {
    width: 56.25%; }
  .col-lg-8 {
    width: 50%; }
  .col-lg-7 {
    width: 43.75%; }
  .col-lg-6 {
    width: 37.5%; }
  .col-lg-5 {
    width: 31.25%; }
  .col-lg-4 {
    width: 25%; }
  .col-lg-3 {
    width: 18.75%; }
  .col-lg-2 {
    width: 12.5%; }
  .col-lg-1 {
    width: 6.25%; }
  .col-lg-pull-16 {
    right: 100%; }
  .col-lg-pull-15 {
    right: 93.75%; }
  .col-lg-pull-14 {
    right: 87.5%; }
  .col-lg-pull-13 {
    right: 81.25%; }
  .col-lg-pull-12 {
    right: 75%; }
  .col-lg-pull-11 {
    right: 68.75%; }
  .col-lg-pull-10 {
    right: 62.5%; }
  .col-lg-pull-9 {
    right: 56.25%; }
  .col-lg-pull-8 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 43.75%; }
  .col-lg-pull-6 {
    right: 37.5%; }
  .col-lg-pull-5 {
    right: 31.25%; }
  .col-lg-pull-4 {
    right: 25%; }
  .col-lg-pull-3 {
    right: 18.75%; }
  .col-lg-pull-2 {
    right: 12.5%; }
  .col-lg-pull-1 {
    right: 6.25%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-16 {
    left: 100%; }
  .col-lg-push-15 {
    left: 93.75%; }
  .col-lg-push-14 {
    left: 87.5%; }
  .col-lg-push-13 {
    left: 81.25%; }
  .col-lg-push-12 {
    left: 75%; }
  .col-lg-push-11 {
    left: 68.75%; }
  .col-lg-push-10 {
    left: 62.5%; }
  .col-lg-push-9 {
    left: 56.25%; }
  .col-lg-push-8 {
    left: 50%; }
  .col-lg-push-7 {
    left: 43.75%; }
  .col-lg-push-6 {
    left: 37.5%; }
  .col-lg-push-5 {
    left: 31.25%; }
  .col-lg-push-4 {
    left: 25%; }
  .col-lg-push-3 {
    left: 18.75%; }
  .col-lg-push-2 {
    left: 12.5%; }
  .col-lg-push-1 {
    left: 6.25%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-16 {
    margin-left: 100%; }
  .col-lg-offset-15 {
    margin-left: 93.75%; }
  .col-lg-offset-14 {
    margin-left: 87.5%; }
  .col-lg-offset-13 {
    margin-left: 81.25%; }
  .col-lg-offset-12 {
    margin-left: 75%; }
  .col-lg-offset-11 {
    margin-left: 68.75%; }
  .col-lg-offset-10 {
    margin-left: 62.5%; }
  .col-lg-offset-9 {
    margin-left: 56.25%; }
  .col-lg-offset-8 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 43.75%; }
  .col-lg-offset-6 {
    margin-left: 37.5%; }
  .col-lg-offset-5 {
    margin-left: 31.25%; }
  .col-lg-offset-4 {
    margin-left: 25%; }
  .col-lg-offset-3 {
    margin-left: 18.75%; }
  .col-lg-offset-2 {
    margin-left: 12.5%; }
  .col-lg-offset-1 {
    margin-left: 6.25%; }
  .col-lg-offset-0 {
    margin-left: 0%; } }

@media (min-width: 1320px) {
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16 {
    float: left; }
  .col-xl-16 {
    width: 100%; }
  .col-xl-15 {
    width: 93.75%; }
  .col-xl-14 {
    width: 87.5%; }
  .col-xl-13 {
    width: 81.25%; }
  .col-xl-12 {
    width: 75%; }
  .col-xl-11 {
    width: 68.75%; }
  .col-xl-10 {
    width: 62.5%; }
  .col-xl-9 {
    width: 56.25%; }
  .col-xl-8 {
    width: 50%; }
  .col-xl-7 {
    width: 43.75%; }
  .col-xl-6 {
    width: 37.5%; }
  .col-xl-5 {
    width: 31.25%; }
  .col-xl-4 {
    width: 25%; }
  .col-xl-3 {
    width: 18.75%; }
  .col-xl-2 {
    width: 12.5%; }
  .col-xl-1 {
    width: 6.25%; }
  .col-xl-pull-16 {
    right: 100%; }
  .col-xl-pull-15 {
    right: 93.75%; }
  .col-xl-pull-14 {
    right: 87.5%; }
  .col-xl-pull-13 {
    right: 81.25%; }
  .col-xl-pull-12 {
    right: 75%; }
  .col-xl-pull-11 {
    right: 68.75%; }
  .col-xl-pull-10 {
    right: 62.5%; }
  .col-xl-pull-9 {
    right: 56.25%; }
  .col-xl-pull-8 {
    right: 50%; }
  .col-xl-pull-7 {
    right: 43.75%; }
  .col-xl-pull-6 {
    right: 37.5%; }
  .col-xl-pull-5 {
    right: 31.25%; }
  .col-xl-pull-4 {
    right: 25%; }
  .col-xl-pull-3 {
    right: 18.75%; }
  .col-xl-pull-2 {
    right: 12.5%; }
  .col-xl-pull-1 {
    right: 6.25%; }
  .col-xl-pull-0 {
    right: auto; }
  .col-xl-push-16 {
    left: 100%; }
  .col-xl-push-15 {
    left: 93.75%; }
  .col-xl-push-14 {
    left: 87.5%; }
  .col-xl-push-13 {
    left: 81.25%; }
  .col-xl-push-12 {
    left: 75%; }
  .col-xl-push-11 {
    left: 68.75%; }
  .col-xl-push-10 {
    left: 62.5%; }
  .col-xl-push-9 {
    left: 56.25%; }
  .col-xl-push-8 {
    left: 50%; }
  .col-xl-push-7 {
    left: 43.75%; }
  .col-xl-push-6 {
    left: 37.5%; }
  .col-xl-push-5 {
    left: 31.25%; }
  .col-xl-push-4 {
    left: 25%; }
  .col-xl-push-3 {
    left: 18.75%; }
  .col-xl-push-2 {
    left: 12.5%; }
  .col-xl-push-1 {
    left: 6.25%; }
  .col-xl-push-0 {
    left: auto; }
  .col-xl-offset-16 {
    margin-left: 100%; }
  .col-xl-offset-15 {
    margin-left: 93.75%; }
  .col-xl-offset-14 {
    margin-left: 87.5%; }
  .col-xl-offset-13 {
    margin-left: 81.25%; }
  .col-xl-offset-12 {
    margin-left: 75%; }
  .col-xl-offset-11 {
    margin-left: 68.75%; }
  .col-xl-offset-10 {
    margin-left: 62.5%; }
  .col-xl-offset-9 {
    margin-left: 56.25%; }
  .col-xl-offset-8 {
    margin-left: 50%; }
  .col-xl-offset-7 {
    margin-left: 43.75%; }
  .col-xl-offset-6 {
    margin-left: 37.5%; }
  .col-xl-offset-5 {
    margin-left: 31.25%; }
  .col-xl-offset-4 {
    margin-left: 25%; }
  .col-xl-offset-3 {
    margin-left: 18.75%; }
  .col-xl-offset-2 {
    margin-left: 12.5%; }
  .col-xl-offset-1 {
    margin-left: 6.25%; }
  .col-xl-offset-0 {
    margin-left: 0%; } }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem; }

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1); }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1); }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 60px;
  padding: 0; }
  .spinner svg {
    width: 32px;
    height: 32px;
    animation-timing-function: linear;
    animation-name: spinner-animation;
    animation-iteration-count: infinite;
    animation-duration: 0.75s; }

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.panel-form-col {
  position: relative; }

.menu-item {
  cursor: pointer;
  margin-right: 15px; }
  .menu-item i + span {
    margin-left: 5px; }

.panel-header {
  display: flex;
  align-items: center;
  padding-right: 0; }
  .panel-header h4 {
    flex-grow: 1; }
  .panel-header .dropdown {
    margin-top: 5px;
    margin-right: -15px; }
  .panel-header a:hover,
  .panel-header .menu-item:hover {
    color: #f56709;
    transition: color 0.3s; }

.panel-body {
  padding: 0; }

.nav-tabs {
  background-color: #f0f0f0; }

.tab-content > .active {
  opacity: 1; }

.form-group label {
  display: flex;
  align-items: center; }
  .form-group label .text {
    flex-grow: 1; }
  .form-group label .validation-message {
    margin: 0; }

.popover {
  font-size: 12px;
  background-color: #ffffff;
  border-radius: 10px;
}

.header-top .language-container .flag {
  margin-right: 5px; }

/*
.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  //transition: all 0.3s ease-out !important;

  > .modal-dialog {
    transition: all 0.3s ease-out !important;
    transform: translate(0, -100%) !important;
    z-index: 1050;
    display: flex;
    flex-grow: 1;

    > .modal-content {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      > .modal-body {
        padding: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
      > .modal-header {
        border-bottom: none;
        display: flex;
        align-items: center;

        > .modal-title {
          font-size: 16px;
          text-transform: uppercase;
          font-weight: bold;
          flex-grow: 1;
          text-align: center;
        }
        > button {
          position: absolute;
          right: 20px;
        }
      }
    }
  }

  &.show {
    opacity: 1;

    // &:before {
    //   position: fixed;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 1040;
    //   background-color: #000;
    //   opacity: 0.7;
    //   content: " ";
    // }

    > .modal-dialog {
      transform: translate(0, 0) !important;
      //transform: translate(0, -10%) !important;
    }
  }
}

.modal-backdrop {
  transition: opacity 0.1s linear;
  &.show {
    opacity: 0.6;
  }
}
*/
.modal > .modal-dialog {
  max-height: 85%;
}

.modal > .modal-dialog > .modal-content {
  max-height: 100%;
}

.modal > .modal-dialog > .modal-content > .modal-header {
  margin: -1px -1px 0px -1px;
  height: 50px;
}

.modal > .modal-dialog > .modal-content > .modal-body {
  height: 100%;
  height: calc(100% - 50px);
}

.modal > .modal-dialog.auth-modal .form-control {
  margin-bottom: 10px; }

.modal > .modal-dialog.auth-modal .modal-content > .modal-body {
  overflow: hidden; }

.modal > .modal-dialog.auth-modal.mode-login {
  width: 300px;
  max-width: 300px;
  height: 400px; }

.modal > .modal-dialog.auth-modal.mode-registration {
  width: 700px;
  max-width: 700px;
  height: 600px; }
  .modal > .modal-dialog.auth-modal.mode-registration .modal-body > .form {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    .modal > .modal-dialog.auth-modal.mode-registration .modal-body > .form > .tab-content {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden; }
      .modal > .modal-dialog.auth-modal.mode-registration .modal-body > .form > .tab-content h5 {
        margin-top: -5px;
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 13px; }
    .modal > .modal-dialog.auth-modal.mode-registration .modal-body > .form > .form-row-buttons {
      margin: 20px 0 0;
      display: flex;
      align-items: center;
      text-align: left; }
      .modal > .modal-dialog.auth-modal.mode-registration .modal-body > .form > .form-row-buttons > div:first-of-type {
        flex-grow: 1; }

.modal > .modal-dialog.auth-modal.mode-remind-password {
  width: 600px;
  max-width: 600px;
  height: 420px; }

.modal > .modal-dialog.modal-news-item {
  width: 850px;
  max-width: 850px; 
}
.modal > .modal-dialog.modal-news-item .modal-body {
  overflow-y: auto;
}

.modal > .modal-dialog.side-login-guard {
  width: 400px;
  max-width: 400px;
  height: 400px; }
  .modal > .modal-dialog.side-login-guard .guard-body {
    text-align: center; }
    .modal > .modal-dialog.side-login-guard .guard-body .guard-timer {
      margin: 6px; }
      .modal > .modal-dialog.side-login-guard .guard-body .guard-timer svg {
        fill: white;
        stroke: black;
        stroke-width: 1; }
      .modal > .modal-dialog.side-login-guard .guard-body .guard-timer text {
        font-size: 24px;
        font-weight: bold;
        stroke: #aaa;
        fill: #aaa;
        text-anchor: middle; }
      .modal > .modal-dialog.side-login-guard .guard-body .guard-timer circle {
        fill: transparent;
        stroke: #dcdcdc; }
      .modal > .modal-dialog.side-login-guard .guard-body .guard-timer path {
        fill: transparent; }
      .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks {
        stroke-width: 0.3;
        stroke: #000;
        stroke-linecap: round; }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(1) {
          transform: rotate(calc(1 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(2) {
          transform: rotate(calc(2 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(3) {
          transform: rotate(calc(3 * 30deg));
          stroke-width: 2; }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(4) {
          transform: rotate(calc(4 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(5) {
          transform: rotate(calc(5 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(6) {
          transform: rotate(calc(6 * 30deg));
          stroke-width: 2; }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(7) {
          transform: rotate(calc(7 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(8) {
          transform: rotate(calc(8 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(9) {
          transform: rotate(calc(9 * 30deg));
          stroke-width: 2; }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(10) {
          transform: rotate(calc(10 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(11) {
          transform: rotate(calc(11 * 30deg)); }
        .modal > .modal-dialog.side-login-guard .guard-body .guard-timer .marks > line:nth-child(12) {
          transform: rotate(calc(12 * 30deg));
          stroke-width: 2; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: unset; }

.pagination-block {
  margin-bottom: 15px;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center; }
  .pagination-block .pagination {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;
    font-size: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center; }
    .pagination-block .pagination li {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 5px;
      font-size: 14px; }
      .pagination-block .pagination li > a,
      .pagination-block .pagination li > span {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        min-width: 36px;
        height: 36px;
        border-radius: 50%;
        text-align: center;
        color: #323232;
        line-height: 24px;
        border: none;
        padding: 6px; }
      .pagination-block .pagination li > a:hover, .pagination-block .pagination li > a:active, .pagination-block .pagination li > a:focus {
        text-decoration: none;
        color: #f56709;
        transition: color 0.3s;
        background-color: transparent; }
      .pagination-block .pagination li.current > a,
      .pagination-block .pagination li.current > span {
        background: #f56709;
        color: #fff; }
      .pagination-block .pagination li.disabled > a,
      .pagination-block .pagination li.disabled > span {
        color: #ccc;
        cursor: default; }
      .pagination-block .pagination li.disabled > a:hover,
      .pagination-block .pagination li.disabled > span:hover {
        color: #ccc; }
      .pagination-block .pagination li.first > a,
      .pagination-block .pagination li.first > span, .pagination-block .pagination li.last > a,
      .pagination-block .pagination li.last > span, .pagination-block .pagination li.prev > a,
      .pagination-block .pagination li.prev > span, .pagination-block .pagination li.next > a,
      .pagination-block .pagination li.next > span {
        width: auto; }
      .pagination-block .pagination li.prev > a,
      .pagination-block .pagination li.prev > span,
      .pagination-block .pagination li.next > a,
      .pagination-block .pagination li.next > span {
        width: auto; }
      .pagination-block .pagination li.prev {
        margin-right: 15px; }
      .pagination-block .pagination li.next {
        margin-left: 15px; }
      .pagination-block .pagination li > a:after,
      .pagination-block .pagination li > span:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        font-family: 'FontAwesome'; }
      .pagination-block .pagination li.first > a:before,
      .pagination-block .pagination li.first > span:before {
        margin-right: 5px; }
      .pagination-block .pagination li.last > a:after,
      .pagination-block .pagination li.last > span:after {
        margin-left: 5px; }
      .pagination-block .pagination li.prev > a:before,
      .pagination-block .pagination li.prev > span:before {
        content: "\f100";
        margin-right: 5px; }
      .pagination-block .pagination li.next > a:after,
      .pagination-block .pagination li.next > span:after {
        content: "\f101";
        margin-left: 5px; }

.button[disabled] {
  opacity: 0.55; }

.form-group {
  padding-bottom: 15px;
  margin: 0; }

input[type="checkbox"][value="false"] + label:before {
  content: "\f096";
  color: #999; }

input[type="checkbox"][value="true"] + label:before {
  content: "\f046";
  color: #1b6ba8; }

.validation-message {
  margin-top: -10px;
  color: red; }

.grow {
  flex-grow: 1; }

.max-250 {
  max-width: 250px; }

.max-300 {
  max-width: 300px; }

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .flex-row .form-group {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .flex-row .form-group:first-of-type label {
      margin-left: 0; }
    .flex-row .form-group.grow input[type='text'].form-control {
      width: 100%; }
    .flex-row .form-group label {
      margin: 0 5px 0 10px;
      white-space: normal; }

.news-add-edit .form-group {
  padding: 0; }

.news-add-edit .label-1 {
  min-width: 80px;
  max-width: 80px; }

.news-add-edit .right-side {
  margin-left: 15px;
  display: flex;
  flex-direction: column; }
  .news-add-edit .right-side .image-container {
    flex-grow: 1; }
    .news-add-edit .right-side .image-container .image-wrapper {
      width: 130px;
      height: 100px;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column; }
  .news-add-edit .right-side .fld-show {
    justify-content: flex-end; }

.news-add-edit .buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .news-add-edit .buttons > button {
    min-width: 100px;
    margin-left: 10px; }

.multi-select {
  /* z-index: 10; */
  margin-bottom: 13px;
  /* .multi-select__indicator.multi-select__dropdown-indicator {
    min-height: 28px;
    padding: 0;
    width: 20px;
  } */
  /* .multi-select__indicator.multi-select__clear-indicator {
    min-height: 28px;
    padding: 0;
    width: 20px;
  } */ }
  .multi-select .multi-select__input {
    margin-top: -2px; }
    .multi-select .multi-select__input > input[type="text"] {
      height: 24px !important;
      margin: -2px 0 0 0 !important; }
  .multi-select .multi-select__control,
  .multi-select > div[class$="__control"] {
    background-color: transparent;
    min-height: 0 !important;
    /* border-color: hsl(0,0%,80%) !important; */
    outline: none;
    /* border-color: #999 !important; */
    border: 1px solid #cbcfd1 !important;
    box-shadow: none; }
    .multi-select .multi-select__control:focus-within,
    .multi-select > div[class$="__control"]:focus-within {
      border-color: #999 !important;
      box-shadow: none; }
  .multi-select .multi-select__indicators .multi-select__indicator {
    align-self: flex-start;
    position: relative;
    color: #323232 !important;
    min-height: 28px;
    padding: 0;
    width: 20px; }
    .multi-select .multi-select__indicators .multi-select__indicator svg {
      display: none; }
    .multi-select .multi-select__indicators .multi-select__indicator.multi-select__clear-indicator:after {
      width: auto;
      height: auto;
      float: right;
      content: "\f00d";
      font: normal normal normal 11px/1 FontAwesome;
      border: none;
      position: absolute;
      right: 7px;
      top: 0.8em; }
    .multi-select .multi-select__indicators .multi-select__indicator.multi-select__dropdown-indicator:after {
      width: auto;
      height: auto;
      float: right;
      content: "\f0dc";
      font: normal normal normal 11px/1 FontAwesome;
      border: none;
      position: absolute;
      right: 7px;
      top: 0.8em; }
  .multi-select .multi-select__indicator-separator {
    display: none; }
  .multi-select .multi-select__value-container {
    overflow-y: visible !important; }
    .multi-select .multi-select__value-container div:last-of-type {
      height: 20px; }
    .multi-select .multi-select__value-container .multi-select__multi-value .multi-select__multi-value__label {
      font-size: 12px;
      padding: 2px 3px 2px 6px; }

/* .multi-select > div[class$="__control"] > div[class$="__value-container"]  input[type="text"] {
  height: 24px !important;
  margin: 0 !important;
} */
/* .multi-select > div[class$="__control"] > div[class$="__indicators"]  [class$="__indicator-separator"] {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
} */
.my-dropdown {
  width: 100%;
  /* margin-bottom: 13px; */
  margin: 0;
  flex-direction: column; }
  .my-dropdown .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    border: 1px solid #cbcfd1;
    font-size: 12px;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis; }
  .my-dropdown .dropdown-menu {
    padding: 0;
    min-width: 100%; }

.dropdown-toggle {
  box-shadow: none !important; }
  .dropdown-toggle:focus {
    border-color: #999; }
  .dropdown-toggle::after {
    margin-left: 5px;
    vertical-align: middle; }

.dropdown-menu {
  /* position: absolute;
  top: 100%;
  left: -9999px; */
  padding: 5px 5px;
  /* overflow: hidden; */
  max-height: 200px;
  overflow: auto;
  background: #fff;
  border: 1px solid #cbcfd1;
  border-radius: 10px;
  box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);
  white-space: nowrap;
  color: #323232;
  /* opacity: 0; */
  z-index: 21; }

.my-dropdown .dropdown-toggle::after {
  width: auto;
  height: auto;
  float: right;
  /* margin: .4em -.2em 0 .5em; */
  content: "\f0dc";
  font: normal normal normal 11px/1 FontAwesome;
  border: none;
  position: absolute;
  right: 7px;
  top: 0.8em; }

.react-datepicker {
  /* .react-datepicker__input-container{
    background-image: none !important;
  } */
  /* .row > div {
    padding-left: 8px;
    padding-right: 8px;
  } */ }
  .react-datepicker .react-datepicker-popper {
    z-index: 10; }
  .react-datepicker .react-datepicker-popper .react-datepicker__navigation {
    height: 0; }
  .react-datepicker .react-datepicker__input-container::after {
    /* width: auto;
    height: auto; */
    position: absolute;
    top: 0.75em;
    right: 5px;
    /* content: "\f0dc"; */
    content: "\f073";
    font: normal normal normal 12px/1 FontAwesome;
    /* border: none; */ }

.panel {
  flex-grow: 1; }

.company-profile .form-group {
  padding-bottom: 0; }

.chosen-container .chosen-results li.active-result {
  white-space: normal !important; }

.input-text input[type="number"], .input-text input[type="text"] {
  padding-right: unset; }

input::-moz-placeholder {
  color: #ccc;
  opacity: 1; }

input:-ms-input-placeholder {
  color: #ccc; }

input::-webkit-input-placeholder {
  color: #ccc; }

@media (max-width: 1319px) {
  .panel-filter-search .search-container-secondary {
    width: auto; } }

@media (min-width: 992px) {
  .priority-nav > .priority-nav__wrapper {
    display: none; } }

@media (min-width: 768px) and (max-width: 991px) {
  .priority-nav > ul.menu-main > li {
    display: none; }
  .priority-nav > ul.menu-main > li:nth-of-type(-n + 3) {
    display: inline-block; }
  .priority-nav > .priority-nav__wrapper > .priority-nav__dropdown > li:nth-of-type(-n + 3) {
    display: none; } }

@media (min-width: 745px) and (max-width: 767px) {
  .priority-nav > ul.menu-main > li {
    display: none; }
  .priority-nav > ul.menu-main > li:nth-of-type(-n + 4) {
    display: inline-block; }
  .priority-nav > .priority-nav__wrapper > .priority-nav__dropdown > li:nth-of-type(-n + 4) {
    display: none; } }

@media (min-width: 650px) and (max-width: 744px) {
  .priority-nav > ul.menu-main > li {
    display: none; }
  .priority-nav > ul.menu-main > li:nth-of-type(-n + 3) {
    display: inline-block; }
  .priority-nav > .priority-nav__wrapper > .priority-nav__dropdown > li:nth-of-type(-n + 3) {
    display: none; } }

@media (min-width: 578px) and (max-width: 649px) {
  .priority-nav > ul.menu-main > li {
    display: none; }
  .priority-nav > ul.menu-main > li:nth-of-type(-n + 2) {
    display: inline-block; }
  .priority-nav > .priority-nav__wrapper > .priority-nav__dropdown > li:nth-of-type(-n + 2) {
    display: none; } }

@media (min-width: 403px) and (max-width: 577px) {
  .priority-nav > ul.menu-main > li {
    display: none; }
  .priority-nav > ul.menu-main > li:nth-of-type(-n + 1) {
    display: inline-block; }
  .priority-nav > .priority-nav__wrapper > .priority-nav__dropdown > li:nth-of-type(-n + 1) {
    display: none; } }

@media (max-width: 402px) {
  .priority-nav > ul.menu-main > li {
    display: none; }
  .priority-nav > ul.menu-main > li:nth-of-type(-n + 0) {
    display: inline-block; }
  .priority-nav > .priority-nav__wrapper > .priority-nav__dropdown > li:nth-of-type(-n + 0) {
    display: none; } }

.redux-toastr .top-left,
.redux-toastr .top-center,
.redux-toastr .top-right {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.color-red {
  color: #dd2c00; }

label.required-field::after {
  content: '*';
  color: #dd2c00;
  margin-left: 2px; }
