:root {
  --ck-image-style-spacing: 1.5em;
}

.news-item_wrapper {
  .news-video-block {
    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .article-title-label {
    margin: 0 0 10px;
  }
  .main-img {
    max-width: 40%;
  }

  .image-style-side,
  .image-style-align-left,
  .image-style-align-center,
  .image-style-align-right {
    max-width: 75%;
  }

  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }
  .news-item-container {
    .meta-links {
      margin-bottom: 0px;
      .news-item_date-time, span {
        margin-right: 5px;
        .news-item_time {
          margin-left: 5px;
        }
      }
    }
  }
  .short-descrtiption {
    font-weight: 700;
    padding: 0px 15px 15px 15px;
  }
  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }
  figcaption {
    text-align: center;
  }

  .panel-content-desc {
    p {
      white-space: pre-wrap;
    }
  }

  .video-block {
    padding-bottom: 20px;
    height: auto;
  }
  iframe{
    width: 100% !important;
    height: 500px !important;
  }
}

.news-item_date-time {
  .fa {
    margin-right: 3px;
  }
  .news-item_date {
  }
  .news-item_time {
    margin-left: 15px;
  }
}

.news-item_main-img {
  border: 1px solid #000;
}

.news-item_provider-unknown {
  background-color: #eee;
  border: 1px solid #777;
  padding: 50px;
}
iframe {
  width: 560px;
  min-height: 315px;
  border: none;
  margin: 10px 0 20px 50%;
  transform: translateX(-50%);
}

iframe.news-item_video-frame {
  width: 100%;
  min-height: 410px;
  border: none;
  margin: 10px;
  transform: none;
}
