@import '../../palette/palette.scss';

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  &.show {
    overflow: hidden;
  }
  > .modal-dialog {
    transition: all 0.3s ease-out !important;
    transform: translate(0, -100%) !important;
    z-index: 1050;
    display: flex;
    flex-grow: 1;

    > .modal-content {
      width: 100%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .centered-container{
        overflow-y: auto;
      }

      > .modal-body {
        padding: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        // overflow: auto;
      }
      > .modal-header {
        border-bottom: none;
        display: flex;
        align-items: center;

        > .modal-title {
          font-size: 16px;
          text-transform: uppercase;
          font-weight: bold;
          flex-grow: 1;
          text-align: center;
        }
        > button {
          position: absolute;
          right: 20px;
        }
      }
    }
  }

  &.show {
    opacity: 1;

    > .modal-dialog {
      transform: translate(0, 0) !important;
    }
  }
}

.modal-backdrop {
  transition: opacity 0.1s linear;
  &.show {
    opacity: 0.4;
  }
}


/*
.tr-modal-overlay_overlay {
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  align-items: stretch;
  overflow: hidden;
  background-color: rgba(44, 52, 57, 0.8);
}

.tr-modal-overlay_closeBtn {
  align-self: flex-end;
  margin-bottom: 15px;
  cursor: pointer;
  color: $white;
  height: 25px;
  width: 25px;
}

.tr-modal-overlay_scrollView {
  width: 100%;
  overflow-y: auto;
  align-items: center;
}

.tr-modal-overlay_container {
  align-items: center;
  margin-top: 37px;
  margin-bottom: 40px;
}
*/