.ads {
    .main-content {
        padding-bottom: 0px;
    }
    .additional-styles {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
    }
    .th-styles-left {
        border-bottom: 1px solid #36454f;
        border-left-color: #36454f;
    }
    th {
        padding: 5px !important;
        text-align: center;
    }
    .th-styles-right {
        border-bottom: 1px solid #36454f;
        border-right-color: #36454f;
    }
    thead {
        tr {
            color: #fff;
            background: #36454f;
        }
    }
    tbody {
        tr {
            cursor: pointer;
        }
        td {
            vertical-align: inherit !important;
        }
    }
    .table-middle-title {
        padding: 12px 2px;
        font-weight: 600;
    }
    .place {
        color: #323232;
        font-weight: 500;
    }
    .notes {
        padding: 5px 10px;
        text-align: left;
    }
    .adstable {
        td > span,
        td > div,
        td > a {
            cursor: default;
            pointer-events: none;
        }
    }
    .c-pointer {
        td > span,
        td > div,
        td > a {
            cursor: pointer !important;
        }
    }
}
.padding-zero{
    padding-top: 0px;
}

.size {  
    width: 215px;
}

@media (min-width: 0px) and (max-width: 767px){
    .table-ads tr, .table-striped-main tr{
        border-left: 1px solid #bdc1c3;
        border-right: 1px solid #bdc1c3;
    }
    .table-responsive{
        border: 1px solid #bdc1c3;
    }
}
