
.date-selector-calendar_wrapper {

  .date-selector-calendar_calendar-selector {
    position: relative;

    .date-selector-calendar_calendar-wrapper,
    .cargo-add_calendar-wrapper {
      position: absolute;
      top: 20px;
      z-index: 9;
    }
  }

  .react-datepicker {
    box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.3);
    border-radius: 10px;
    font-size: 12px;
    .react-datepicker__header{
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .react-datepicker__current-month {
      font-size: 12px;
    }

    .react-datepicker__day-name {
      width: 20px;
    }
  }
}