$arrowSize: 8px;
$black: #000;

.bullet-tooltip {
    .tooltip {
        background: none;
        box-shadow: none;
        border: none;
        &::before {
            content: "";
            position: absolute;
            bottom: calc(#{$arrowSize} / 2);
            left: 50%;
            width: $arrowSize;
            height: $arrowSize;
            background-color: red;
            transform: rotate(45deg) translateX(-50%);
            background-color: $black;
        }
    }
}
.bullet-active {
    background-color: #1b6ba8 !important;
}
.bullet-unactive {
    background-color: #999 !important;
}