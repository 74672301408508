.show-modal-tooltip {
  display: block;
  .user-is-blocked {
    position: absolute;
    right: 0px;
    color: #ff0000;
    font-weight: 700;
    width: 100px;

    .user-blocked-image {
      width: 35px !important;
      border-radius: 100%;
    }
  }
}
.user-modal-avatar {
  margin-right: 10px;
  display: flex !important;
  align-items: center;
  float: left;
}
.user-company-name {
  color: #1b6ba8;
}
.popover-content {
  min-width: 335px;
  padding: 0px;
  min-height: 90px;
  &.is-loading {
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-height: 85px;
  }
}
.tooltip-reviews-content {
  display: block !important;
  width: 100% !important;
  text-align: center;
}
.td-company {
  position: relative;
}
@media screen and (max-width: 991px) {
  .td-company > span {
    width: 210px;
  }
}
.tooltip {
  display: none;
  left: -120px;
  top: -95px;
  z-index: 9999999999;
  position: absolute;
  background: #fff;
  padding: 10px;
  border: 1px solid #cbcfd1;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);
  -webkit-box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);
  box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);

  .list-flags {
    max-width: 300px;
    float: right;
  }
}
.user-modal-avatar {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-size: cover;
  background-position: center center;
}

.small-container {
  top: -75px;
}
.show-modal-tooltip {
  display: block;
  .user-is-blocked {
    position: absolute;
    right: 0px;
    color: #ff0000;
    font-weight: 700;
    width: 100px;
    text-align: right;
    padding-right: 10px;

    .user-blocked-image {
      width: 35px !important;
      border-radius: 100%;
    }
  }
}
.col-green {
  margin-right: 5px;
}
.tooltip {
  opacity: 1;
}
.tooltip-body {
  opacity: 1;
  position: absolute;
  z-index: 9999;
  max-width: 100%;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.tooltip-body .tooltip-wrapper {
  display: block;
  padding-bottom: 10px;
  z-index: 20;
}
.tooltip-body .tooltip-lg {
  width: auto;
  min-width: 200px;
}
.tooltip-body .tooltip {
  display: block;
  padding: 10px 15px;
  background: #fff;
  border: 1px solid #cbcfd1;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);
  -webkit-box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);
  box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);
  text-align: center;
  cursor: default;
  white-space: normal;
}
.company-info {
  text-align: left;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  .list-stars {
    min-height: 18px;
  }
}
.col-green {
  color: #56bf42 !important;
}
.list-flags {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 3px 0;
  list-style: none;
  font-size: 0;
}
.list-flags li {
  display: inline-block;
  margin-left: 5px;
  line-height: 1;
  font-size: 12px;
}
.list-flags li span {
  -moz-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  overflow: hidden;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.company-info-row {
  display: flex;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  align-items: flex-end;
  justify-content: space-between;
}
.company-info-cell {
  display: table-cell;
  vertical-align: top;
}
.company-info-cell.ta-l span.meta {
  span {
    margin-left: 5px;
  }
  .fa-calendar {
    margin-right: 0px;
  }
}
.company-info-cell > span,
.company-info-cell > a {
  white-space: nowrap;
  display: block;
  width: auto;
}

.panel-table {
  overflow: visible;
}
