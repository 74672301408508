.cursmd,
.calc-schengen {
  width: 100%;
  border: 1px solid #cbcfd1;
  border-radius: 10px;
}

.cursmd {
  min-height: 200px;
  margin-bottom: 0;
}

.banner {
  text-align: center;
  margin: 0px 10px 20px 10px;
}

.banner-line {
  display: flex;
  justify-content: center;
}
.banner-container {
  .banner {
    display: none;
    &:first-child {
      display: block;
    }
  }
}
