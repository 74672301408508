.truck-item-bottom {
  .fl-right {
    margin-top: 0px;
  }
}
.no-margin {
  margin: 0px !important;
}
.additional-options {
  margin-top: 10px;
  span {
    @media (max-width: 1320px) {
      display: block;
      width: 100%;
    }
    margin-right: 20px;
    i {
      margin-right: 5px;
    }
  }
}
