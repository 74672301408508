@import "../../../../palette/palette.scss";

.user-logged_dropdown-wrapper {
  font-size: $smallFontSize;

  &.show-dropdown {
    .link-dropdown::after {
      transform: rotate(180deg);
    }
    .dropdown {
      display: block !important;
      opacity: 1 !important;
      top: unset;
    }
  }
  .no-company-links {
    display: flex;
    justify-content: space-between;
  }

  &.dropdown-base_wrapper {
    .dropdown {
      left: auto;
      right: 0px;
    }
  }
  .request-send {
    padding: 15px;
    border-top: 1px solid #ebeced;
  }
  .user-link {
    display: flex;
    align-items: center;

    .img-container {
      display: flex;
      align-items: center;
    }
  }
}
.verification-navigation {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .cancel-my-verification {
    cursor: pointer;
    color: #1b6ba8;
    text-decoration: none;
    outline: none;
    font-size: 12px;
    text-transform: lowercase;
    &:hover {
      text-decoration: underline;
    }
  }
}

.cancel-my-verification {
  cursor: pointer;
}
.user-info-header-cell {
  .disabled {
    color: #ccc;
  }

  .name {
    vertical-align: top;
    width: 190px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media screen and (max-width: 768px) {
      width: auto;
    }
  }
}

.hide-menu-small-window {
  @media screen and (max-width: 768px) {
    width: 0%;
  }
}
.click-container {
  display: none;
}
@media (max-width: 1024px) {
  .click-container {
    width: 60px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    pointer-events: auto;
    display: block;
    z-index: 10;
  }
}

.link-lg {
  &.company-name {
    word-break: break-word;
    color: $baseTextColor;
    font-size: 17px;
  }
}

.link-lg {
  font-size: 12px;
}

.user-logged_company-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .company-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.user-info-footer {
  display: flex;
  flex-direction: column;
}
.overlay-to-close {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
}
.messenger-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
}

.user-logged_modal-messenger {
  position: absolute;
  width: 75%;
/*  min-width: 750px; */
  min-height: 500px;
  height: 75%;

  .modal-header {
    padding: 15px;
    border-radius: 9px 9px 0 0;
    color: #fff;
    background: #36454f;
    text-align: left;

    .close {
      color: #fff;
    }
  }

  .modal-body {
    padding: 0 0 20px !important;
  }
}
.show-messenger.popup-overlay{
  display: block;
  z-index: 9999;
}
.container-fluid.messenger > .row{
  width: 100%;
  margin: 0;
}
.container-fluid.messenger .modal-close-button{
  margin: 12px;
}