.buy-sell-table th:first-child {
  width: 50px;
}

thead {
  tr {
    th {
      border-bottom: none !important;
    }
  }
}

@media screen and (max-width: 1080px) and (min-width: 990px) {
  .buy-sell-table th {
    max-width: 60px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
