.news-comments_right {
  float: right;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  .btn {
    padding: 0;
    margin-left: 4px;
    height: auto;
  }
}

.news-comments_date {
  color: #999;
}
.no-comments {
  text-align: center;
  padding: 30px;
  font-size: 14px;
  font-weight: 700;
}
.news-item-title {
  display: flex !important;
  justify-content: space-between;
}
.news-comment-panel img {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}
.panel-comment-desc {
  word-break: break-all;
}
.news-comments-container {
  .ui-pagination {
    margin-bottom: 20px;
  }
}
