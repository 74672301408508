.tr-card {
    // &.mb-20 {
    //     .access-dates {
    //         margin-top: 20px;
    //     }
    // }
    .bg-dark {
        background-color: #36454f;
        color: #fff;
    }
    .disabled {
        opacity: 0.5;
        cursor: no-drop !important;
    }
    .spinner {
        width: 20px;
        height: 20px;
        top: 7px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }
    .access-point-description{
        padding-bottom: 20px !important;
    }
}
