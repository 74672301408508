// .move-up-container {
//   .popover {
//     top: -14px !important;
//   }
// }
.popover {
  top: 0px !important;
}
.show {
  transition: opacity 0.2s linear;
}
.popover-styles {
  border-radius: 10;
  text-align: center;
  max-width: 200px;
}
