// palette.scss

// Font size
$h1FontSize: 32px !default;
$h2FontSize: 24px !default;
$h3FontSize: 18px !default;
$baseFontSize: 14px !default;
$smallFontSize: 12px !default;
$xSmallFontSize: 10px !default;

// Line height
$h1LineHeight: 32px !default;
$h2LineHeight: 24px !default;
$h3LineHeight: 18px !default;
$baseLineHeight: 18px !default;
$smallLineHeight: 14px !default;
$xSmallineHeight: 12px !default;

// Colors
$white: #FFFFFF !default;
$spicyOrange: #EF6540 !default;
$red: #E62325 !default;
$lineGray: #CCCCCC !default;
$errorColor: #F01A0A !default;
$successBannerColor: #36A343 !default;
$zipwhipOrange: #FB713F !default;

$alertRed: #c91508 !default;
$alertErrorBackColor: #FFECEB !default;
$alertGreen: #0a7816 !default;
$alertSuccessBackColor: #F0FEE9 !default;
$alertOrange: #cf3b17 !default;
$alertInfoBackColor: #FFF4E3 !default;

// Text color
$baseTextColor: #2D323A !default;
$secondaryTextColor: #92979F !default;
$linkTextColor: #2C7CB5 !default;
$linkHoverTextColor: #86BBE0 !default;
$linkPressColor: #1E5A8C !default;

// navigation colors (headers and side panels)
$headerBackgroundColor: #2D323A !default;
$sidePanelBackgroundColor: #3E444D !default;
$mainPageDividersColor: #D1D6DD !default;
$secondaryPageDividersColor: #EDEFF2 !default;

// Icon colors over light background
$iconColor: #6E747C !default; // base for action icons
$iconHoverColor: #86BBE0 !default;
$iconPressColor: #1E5A8C !default;
// Icon colors over dark background
$iconOverDarkColor: #AAAAAA !default; // base for action icons
$iconOverDarkHoverColor: #FFFFFF !default;
$iconOverDarkPressColor: #1E5A8C !default; // 03/13/2018 Placeholder; not yet used
$iconToggleOnColor: #549ED1 !default;

// default gray icon color; seperating this from text color on purpose
// (works on light background and dark background)
$staticIconColor: #AAAAAA !default;

// avatar Colors
$defaultAvatarColor: #B7B9BC !default;
$defaultAvatarBackground: #EDEFF2 !default;
$avatarHoverColor: #D1D6DD !default;
$avatarHoverBackground: #FFFFFF !default;

// form fields color
$fieldPlaceholderColor: #ccc;
$fieldIsRequiredColor: #aaa !default;
$fieldIsValidColor: #36A343 !default;
$fieldIsInvalidColor: #c91508 !default;

// Spacing scale
$spaceOne: 8px !default;
$spaceTwo: 16px !default;
$spaceThree: 24px !default;
$spaceFour: 32px !default;
$spaceFive: 40px !default;
$spaceSix: 48px !default;
$spaceSeven: 72px !default;
$spaceEight: 96px !default;

// Typography
$regularFontFamily: 'proxima-nova', apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
