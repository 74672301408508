.join-verification-company {
  padding: 0px 20px 20px;
  h3,
  h2 {
    text-align: center;
  }
  p {
    text-indent: 20px;
    margin-bottom: 20px;
  }
  .join-img-container {
    display: flex;
    img {
      width: 50%;
    }
  }
}
