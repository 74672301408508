
.file-upload-field_wrapper {
  position: relative;
  width: 120px;
  height: 120px;
  padding: 0;

  &.photo-block {
    border-radius: unset;
    border: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.photo-rounded {
    >.img-container {
      border-radius: 50%;
    }
    >.icon-remove {
      border-radius: 50%;
    }
  }
  
  >.img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #cbcfd1;
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: inherit;
    cursor: pointer;

    a, img {
      object-fit: cover;
      width: 100%;
      height: 100% !important;
    }
  }

  >.icon-remove {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(225, 225, 225, 0.7);
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
  }

  .icon-add {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    top: 0;
    left: 0;
    width: 24px;
    margin: 0;
  }


  .file-uploader_wrapper,
  .fine-uploader-dropzone-container,
  .click-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .fine-uploader-dropzone-container{
    > div {
      text-align: center;
    }

    canvas{
      display: none;
    }

    .click-wrapper{
      > span {
        display: none;
      }
    }
  }
}