.panel-company-desc {
  li {
    word-break: break-word;
  }
}
.company-page.panel-company-footer {
  overflow: visible;
  min-height: 70px;
}
.company-cargo-content {
  padding-bottom: 20px;
  h5 {
    padding-top: 14px;
    padding-left: 14px;
  }
  .table-cargo {
    tr {
      .td-company {
        display: none;
      }
    }
  }
}
.cursor-default {
  cursor: default !important;
}
.mt-2 {
  margin-top: 2px;
}
.rating-container {
  a {
    margin-bottom: 2px;
  }
  .rating-line-container {
    display: flex;
    span {
      margin-right: 5px;
    }
  }
}
.show-certificate {
  cursor: pointer;
  color: #1b6ba8;
}
.company-licenses {
  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  .ico-download {
    margin-right: 5px;
    display: block;
  }
  .license-container {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-position: center;
    background-size: contain;
    margin-left: 10px;
    margin-bottom: 5px;
  }
  li {
    list-style-type: none;
    display: block;
    width: fit-content;
    span {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      width: fit-content;
    }
    a:focus {
      text-decoration: none !important;
    }
  }
}
a:focus {
  text-decoration: none !important;
}
.company-employees-content {
  padding-bottom: 20px;
  h5 {
    padding-top: 14px;
    padding-left: 14px;
  }
  .profile-mini_wrapper {
    width: 49%;
    flex-grow: 0;
    // &:nth-child(2n) {
    //   margin-left: 10px;
    // }
    .user-info-header-name {
      width: 100% !important;
    }
  }
  @media (max-width: 767px){
    .profile-mini_wrapper{
      width: 100%;
      flex-grow: 1;
    }
  }
  .employee-items-content {
    display: flex;
    padding: 20px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .past-employee {
    // opacity: 0.7;
  }
  .link-iconed {
    span {
      display: none;
    }
  }
}
.in-block-list {
  padding-left: 14px;
  b {
    color: #1b6ba8;
    cursor: pointer;
  }
}
.company-claims-content {
  padding-bottom: 20px;
  h5 {
    padding-top: 14px;
    padding-left: 14px;
  }
}
.company-autopark-content {
  padding-bottom: 20px;
  h5 {
    padding-top: 14px;
    padding-left: 14px;
    border-bottom: 1px solid #cbcfd1;
    padding-bottom: 15px;
  }
}

.company-transport-content {
  padding-bottom: 20px;
  h5 {
    padding-top: 14px;
    padding-left: 14px;
  }
}
.company-ads-content {
  padding-bottom: 20px;
  h5 {
    padding-top: 14px;
    padding-left: 14px;
  }
}
.company-reviews-content {
  padding-bottom: 20px;
  h5 {
    padding-top: 14px;
    padding-left: 14px;
  }
}
.one-buss-type {
  margin-bottom: 5px;
  cursor: default;
  word-break: break-word;
}
.verification-content {
  h2 {
    text-align: center;
  }

  img {
    width: 50px;
    height: 50px;
  }
}
.max-width-50 {
  max-width: 50px;
}
.company-confirm {
  position: relative;
  padding: 20px;
  b {
    margin-left: 10px;
  }
  img {
    cursor: pointer;
  }
}
.company-inactive {
  color: #ff0000;
}
.approve-your-action {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.8);
  left: 0px;
  top: 0px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    box-shadow: none !important;
    margin: 0px 5px;
  }
  h1 {
    color: #fff;
  }
  .approve-your-action-container {
    text-align: center;
  }
}
.cancel-verification {
  cursor: pointer;
}
.verification-title {
  text-align: center;
}
.verification-content {
  .button-blue {
    color: #fff;
  }
}
.no-verification-data {
  text-align: center;
  margin: 20px;
  font-style: italic;
}
.action-verification-block {
  display: flex;
  justify-content: space-evenly;
}
.company-page > .panel-company-top-header {
  padding-bottom: 0px;
}
.panel-company-header {
  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 0px;
    margin-right: 20px;
    max-width: 60%;
    // max-width: 190px;
    // min-width: auto;
  }
  .no-verification-data {
    text-align: center;
    margin: 20px;
  }
  .meta {
    margin: 0px;
    margin-right: 5px;
  }
  @media (max-width: 1319px) {
    .link-edit {
      width: auto;
      padding: 0px 10px;
    }
  }
  .additional-styles {
    top: 35px;
    z-index: 9;
  }
  .top-0 {
    top: 15px;
  }
  &.company-iconed {
    padding-bottom: 0px;
  }

  .additional-info-container-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 20px;
    z-index: 9;
    a {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .additional-info {
    display: flex;
    // width: 100%;
    justify-content: space-between;
    .additional-info-container {
      display: flex;
    }
    @media screen and (max-width: 650px) {
      width: auto;
      .meta {
        display: block;
      }
    }
  }
  .additional-info > span {
    margin-right: 10px;
  }
}
.panel-company-top-header {
  display: flex;
}
.no-break-word {
  word-break: break-word !important;
}
