.cargo-counteroffers_wrapper {
  box-shadow: none;
  position: relative;
  .main-img {
    max-width: 40%;
  }

  .btn-link {
    height: auto;
  }

  .panel-settings-content {
    transition: all 500ms ease-in-out;
    max-height: 0;
    padding-bottom: 0;
    overflow: hidden;

    &.opened {
      max-height: 500px;
      padding-bottom: 15px;
    }
  }
  .opacity-0-3 {
    opacity: 0.3;
    label {
      cursor: default !important;
    }
  }
  .input-container {
    label {
      margin-bottom: 0;
    }
  }
  .fa-cog {
    margin-right: 5px;
  }
  .criteries-container {
    color: #323232;
  }
  .see-only-for-you{
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    margin: 15px;
  }
}
.panel-settings .collapsible-content {
  display: inherit !important;
}

.cargo-counteroffers_spinner {
  margin: 100px 20px;
}

.cargo-counteroffers_error {
  color: red;
  margin: 100px 20px;
}

.text-gray {
  color: rgba($color: #000000, $alpha: 0.4);
}

.cargo-counteroffers_item-in-modal {
  width: 96%;
  max-width: 740px;
  min-height: 300px;

  .modal-body {
    padding: 0 0 20px !important;
  }

  // .guard-body {
  //   text-align: center;
  //   .guard-timer {
  //     margin: 6px;
  //     svg {
  //       fill: white;
  //       stroke: black;
  //       stroke-width: 1;
  //     }
  //     text {
  //       font-size: 24px;
  //       font-weight: bold;
  //       stroke: #aaa;
  //       fill:  #aaa;
  //       text-anchor: middle;
  //     }
  //     circle {
  //       fill: transparent;
  //       stroke: #dcdcdc;
  //     }
  //     path {
  //       fill: transparent;
  //     }
  //   }
  // }
}

// .cargo-counteroffers_main-row{
//   display: flex;
//   align-items: stretch;

//   .map-block{
//     height: 100%;
//   }
// }
