.padding-t-r-l{
    padding: 20px 20px 0px;
    &.no-top-padding{
        padding-top: 0;
    }
}
.red{
    color:#ff0000;
}
.distance-result{
    padding: 0px 20px;
}
