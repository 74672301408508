.comments-pagination {
  text-align: right;
  border-top: 1px solid #ebeced !important;
  padding: 5px;
  border-bottom: none !important;
}
.comments-details {
  float: right;
  text-align: right;
  .meta-text {
    float: none;
    margin: 0;
  }
}
.panel-comment-content {
  margin-left: 30px;
}
.office {
  color: #999;
  font-size: 12px;
  margin-left: 10px;
}
.devider {
  color: rgb(27, 107, 168);
  margin: 0px 10px;
}
.panel-comment {
  .panel-message-gray:before {
    content: '\f148';
    transform: rotate(90deg);
    top: unset !important;
    bottom: -18px;
  }
  .panel-comment-desc {
    &.no-qoute p {
      padding-left: 0px;
    }
    p {
      padding-left: 20px;
    }
  }
}
.name-link {
  color: #1b6ba8;
  cursor: pointer;
}
.show-more-details b {
  cursor: pointer;
}
.button-blue {
  background: #36454f;
  float: right;
  -webkit-box-shadow: 0px 3px 0px 0px #868686;
  box-shadow: 0px 3px 0px 0px #868686;
}
.button-red {
  float: right;
  margin: 0px 10px 0px 0px;
  background: #ff0000;
}
.edit-form-container {
  margin-bottom: 40px;
  margin-top: 20px;
}
.remove-container {
  text-align: center;
  width: 300px;
  .button-blue {
    float: none;
  }
  .button {
    margin: 0px 10px 10px;
  }
  .remove-note {
    font-size: 12px;
  }
}
.popup-remove-comment {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
}
.comment-length-error {
  color: #ff0000;
  font-weight: 700;
}
.clear {
  clear: both;
  margin-bottom: 20px;
}
.simple-text {
  margin-bottom: 30px !important;
}
.button-container {
  margin-top: -30px;
}
.additional-actions a {
  margin-left: 10px;
}
.additional-actions .name-link {
  margin-left: 10px;
}
.comments-action-block {
  .panel-comment-name {
    text-align: right;
    clear: both;
  }
}
.additional-actions {
  margin-top: 0px !important;
}
.comments-pagination span {
  padding-left: 5px;
  cursor: pointer;
  &.active {
    font-weight: 700;
    color: #f56709;
    font-size: 14px;
  }
}
.comment-main-block .spinner {
  min-height: 200px;
}
.comment-main-block .panel-content-row {
  cursor: default;
  position: relative;
  border-bottom: 1px solid #ebeced;
  margin-bottom: -1px;

  .name span {
    max-width: 190px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.highlight-comment {
  background: #ebeced;
  border-top: 1px solid #fff;
  .panel-message-gray {
    background: #fff !important;
  }
}
