.not-logged-for-mobile {
  display: none;
}

@media (max-width: 767px) {
  .not-logged-for-mobile {
    display: flex;
    text-align: right;
    margin-top: -40px;
    padding: 0;
    margin-right: 10px;
  }
  .menu-modal-overlay.is-opened {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
}
  .user-mob .fa,
  .user-mob .ico {
    width: 35px;
    height: 35px;
    background: #323232;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    line-height: 40px;
    color: #fff;
    overflow: hidden;
  }
  .user-container-logged {
    .user-link {
      pointer-events: auto;
    }
    .menu-links {
      position: absolute;
      top: 60px;
      right: 0;
      width: max-content;
      &.show-dropdown {
        display: block;
      }
    }
  }
  .header-bottom .user-container {
    position: relative;
    margin-top: -55px;
  }
}
.header-bottom .user-container {
  position: relative;
}
