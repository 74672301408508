.user-info-header-cell, .user-block {
  img {
    object-fit: cover;
    width: 100%;
    height: 100% !important;
  }

  .name-sm {
    width: 190px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}