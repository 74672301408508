.user-notices-panel {
  .ui-pagination {
    margin: 20px 0;
  }
  
  .header-info-panel .notice-author {
    margin-left: 15px;
  }

  .title, .text {
    word-break: break-word;
    text-overflow: ellipsis;
  }
}