@import '../../../../palette/palette.scss';

.widget-header_wrapper {
  h4 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown_wrapper {
    margin: -5px 10px;

    .dropdown {
      right: 10px;
    }
  }
}