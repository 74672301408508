.message-list-container {
  padding: 10px;
}
.message-list{
  width: 100%;
}
.toolbar-title{
  padding: 10px;
}
.send-message-popup-container{
  &.message-list-container{
    width: 100%;
    max-width: 100%;
    background: none;
    overflow: scroll;
    overflow-x: hidden;
    height: calc(100% - 185px);
    margin-top: 0px;
    
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }
  
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }

  }

  .spinner{
    min-height: 60%;
  }

  h1{
    text-align: center;
  }

  .ta-right{
    text-align: right;
    .sm-message{
      float: right;
      background: #f56709;
    }
    .bubble-date{
      left: -75px;
      right: inherit;
      margin-left: 0px;
    }
  }
  .bubble-date{
    position: absolute;
    top: 0px;
    right: -75px;
    color: #000;
    font-size: 10px;
    //margin-left: 10px;
    display: none;
  }
  .no-content-wrapper{
    width: 100%;
  }
  .sm-message {
    min-width: 50px;
    max-width: 70%;
    float: left;
    color: #444648;
    background: #dbf1ff;
    padding: 10px;
    border-radius: 20px;
    position: relative;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2);
  }
  .display-block{
    display: block !important;
  }
  table{
    display: block;
    margin: auto;
    width: max-content;

      td{
        border: 1px solid #7f7f7f;
        padding: 5px;
        font-weight: 700;
        font-size: 14px;
      }
  }
  .messages-bublles{
    height: 48vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 20px 0px;
    display:flex;
    .spinner{
      height: auto;
    }
  }
  
  .message-bubble{
    padding: 10px 10px 0px 10px;
    width: 100%;
    .sm-author{
      font-weight: 700;
      font-size: 16px;
    }
    .sm-date{
      color: #a70000;
      font-weight: 700;
    }
    .clear{
      clear: both;
    }
  }
  .send-message-container{
    width: 100%;
    bottom: 20px;
  }
}