.edit-panel-form {
  padding: 20px;
  display: flex;
}

.edit-panel-input-block {
  min-width: 190px;
  min-height: 75px;

  .password-control {
    right: 5%;
  }
}

.reregistration-notification-block {
  padding: 20px;
  text-align: justify;
  text-indent: 10px;

  .reregistration-notification-text {
    text-indent: 20px;
  }

  .text-error {
    font-size: 18px;
  }
}

.edit-panel-notification-block {
  margin: auto;
  padding-left: 20px;
}

.change-password-email-modal .panel {
  border: none;
  box-shadow: none;

  .validation-message {
    margin-top: 0;
  }

  .icon-close {
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 7px;
    &:hover {
      color: #f56709;
    }
  }

  .edit-panel-email {
    margin: 20px 0 10px;
  }
}
