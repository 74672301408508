.remove-border {
    td,
    tr,
    thead th {
        border-radius: 0px !important;
        border-top: 1px solid #bdc1c3 !important;
    }
    thead th {
        background: #36454f;
        color: #fff;
    }
}
.employee-table {
    .img-container {
        height: 45px;
        width: 45px;
        background-size: contain;
        background-position: center center;
    }
    td {
        overflow: hidden;
        a {
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: unset !important;
            display: block;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            white-space: nowrap;
        }
    }
    .td-image {
        max-width: 450px;
        width: 450px;
    }
    .default-td {
        max-width: 105px;
        width: 105px;
    }
}
