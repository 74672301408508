.admin-notice-add-panel {
  .placementPeriod {
    width: 30%;
    margin-top: 10px;
  }
  .button {
    margin-top: 5px;
    width: 100px;
  }
  label {
    display: block;
  }
}