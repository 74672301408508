.form-container {
  position: relative;
}

.password-control {
  position: absolute;
  top: 7px;
  right: 27px;
}

input[type="password"].form-control,
input[type="text"].form-control {
  padding-right: 10px;
}