.ads-add-photo {
    border-top: none !important;
    padding: 0;
    .label {
        display: inline-block;
        min-height: 16px;
        margin-bottom: 6px;
        font-weight: 500;
        margin-right: 10px;
    }
    .panel-form-photo {
        overflow: hidden;
        margin: 0 -10px 10px;
        .col {
            width: 20%;
            float: left;
            padding: 0 10px;
            .photo-block {
                position: relative;
                width: 100%;
                overflow: hidden;
                .img-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }
            }
        }
    }
}