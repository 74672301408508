.user-entities {
  position: relative;
  .additional-block {
    display: none;
  }
  .table-cargo .td-city {
    min-width: 200px;
  }
  .fl-right {
    margin-top: 0px;
  }
  @media screen and (max-width: 650px) {
    .additional-block {
      display: block;
      .additional-tabs {
        margin-top: 30px;
        display: none !important;
        &.show-tabs {
          display: block !important;
        }
        div {
          border: none !important;
        }
        .panel-tabs-list li {
          display: block;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            display: none !important;
          }
        }
      }
      .panel-tabs-list li {
        border: none;
        max-width: 100% !important;
      }
    }
    .main-tabs {
      .panel-tabs-list li {
        max-width: 50% !important;
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          display: none !important;
        }
      }
    }
  }

  .main-tabs {
    .panel-tabs-list li {
      &:nth-child(8) {
        display: none !important;
      }
    }
  }

  .cargo-transport-list {
    padding-bottom: 10px;

    .city {
      min-width: 160px;
    }
  }

  .more-tabs-button {
    float: right;
    margin: 5px 0px;
  }

  .not-found {
    height: 60px;
    padding-top: 10px;
  }

  h5 {
    padding-top: 14px;
    padding-left: 14px;
  }
  .car-list-container {
    h2 {
      text-align: center;
    }
  }
  .ui-pagination {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1320px) {
    .panel-tabs-list li {
      max-width: 10%;
      a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
