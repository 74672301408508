.employee-edit {
    // background-color: red;
    .panel-has-user {
        border-radius: 0 0 10px 10px;
        margin-bottom: 15px;
    }
    .alert-banner{
        span{
            color: #000;
        }
    }
}