.add-review {
  h5 {
    padding-left: 0 !important;
  }
  input {
    border: 1px solid #cbcfd1;
    border-radius: 5px;
    padding: 0 10px;
    height: 30px;
  }

  div {
    padding-top: 8px;
  }

  .counter {
    color: rgba($color: #000000, $alpha: .3);
  }

  .response-block {
    padding-top: 0 !important;
    span {
      color: rgba($color: #000000, $alpha: .4);
      margin-right: 8px;
    }
  }
}