.review-answer-date {
  display: flex !important;
  float: right;
  margin: 0px 0px 5px 10px;
}

.review-asnwer {
  padding: 10px;
  border-radius: 6px;
  background-color: #f4f4f4;
}

.review-content {
  position: relative;
  .remove-container {
    width: 420px;
  }
  .panel-message-content, .review-asnwer {
    word-break: break-all;
  }
  .link-message{
    margin-left: 20px;
  }
  .review-author-container {
    display: flex;
    span a {
      max-width: unset;
      margin-left: 5px;
    }
  }
}
