.hide-borders {
  border: 0px !important;
  position: relative;
  right: 4px;
}
.widget-more{
  background-image: url(../../../../icons/arr-more.png);
  background-position: right 0px;
  background-repeat: no-repeat;
  padding-right: 16px !important;
}