.profile_wrapper {
  label {
    display: flex;
  }

  input[readonly] {
    background: #eee;
  }

  .profile_info {
    min-height: 200px;
    padding: 20px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox-block {
    width: 150px;
  }

  .cursor-disabled {
    cursor: not-allowed !important;
    background: #e3e3e3 !important;
    opacity: 0.5;
  }

  .tooltip-verif-company {
    margin-right: 5px;
  }

  .profile_info-required-field {
    font-size: 14px;
    font-weight: 500;
  }

  .profile_wrapper_icon {
    margin: 8px;

    @media screen and (min-width: 768px) {
      position: absolute;
    }
  }

  @media screen and (max-width: 768px) {
    .button-block {
      width: auto;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.render-phone-inline {
  display: flex;
}

.change-password-email-modal {
  max-width: 665px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.registration-warning-panel p {
  width: 250px;
  margin-top: 10px;
}