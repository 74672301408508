.tr-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 4px 4px 4px 0px #cbcfd1;
  margin: 20px auto;
  border: 1px solid #cbcfd1;
  border-radius: 10px;
  width: 80%;
  min-height: 50px;
  flex-direction: column;
  // div {
  //   margin: 0 20px;
  // }
  .access-point-description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 0px;
  }
}
.user-action-buttons-block {
  display: flex;
  justify-content: flex-end;
}
.alert-status {
  color: #ff0000;
  font-weight: 700;
  font-size: 16px;
  height: 100%;
  padding: 5px;
  border-radius: 5px;
}
.access-dates {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
  .banner-line-description {
    display: flex;
    align-items: center;
    margin: 0px 0px 20px;

  }
  .date-label{
    margin-right: 5px;
  }
}