.alert-banner {
  padding: 0;
  float: none;
  .fa-exclamation {
    font-size: 20px;
    text-align: center;
    margin-right: 11px;
  }
  .panel {
    &.panel-warning {
      padding: 8px 20px;
    }
    .panel-body {
      text-align: center;
    }
    h4 {
      margin: 0;
      i {
        margin-right: 5px;
      }
    }
  }
}
