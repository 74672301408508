@import '../../../palette/palette.scss';

.news-list_wrapper{
  h4{
    font-size: $smallFontSize;
    a{
      font-size: $smallFontSize;
    }
  }

  .ui-pagination{
    margin-bottom: 20px;
  }

  .news-list_img-container {
    border: 1px solid #000;
  }

  .table {
    margin: 0;
    .tr-rounded-down {
      >th:first-child,
      >td:first-child {
        border-top-left-radius: 10px;
      }
      >th:last-child,
      >td:last-child {
        border-top-right-radius: 10px;
      }
    }
    .tr-rounded-up {
      >th:first-child,
      >td:first-child {
        border-bottom-left-radius: 10px;
      }
      >th:last-child,
      >td:last-child {
        border-bottom-right-radius: 10px;
      }
    }
    >thead{
      >tr{
        >th{
          border-top: 1px solid #BDC1C3 !important;
          border-color: #BDC1C3;
          padding: 5px;
          text-align: center;
          vertical-align: middle;
          // border-left: 1px solid #cbcfd1;
          // border-right: 1px solid #cbcfd1;
          // border-bottom: 1px solid #cbcfd1;
          font-weight: 400;
        }
      }
    }
    >tbody{
      >tr{
        &.tr-highlighted {
          >td{
            background-color: #B9D5EA;
          }
        }
        >td{
          padding: 5px;
          vertical-align: middle;
          text-align: center;
          border-width: 1px !important;

          >a {
            padding: 0;
          }

          &.num{
            min-width: 30px;
            text-align: center;
          }

          &.category {
            min-width: 100px;
            text-align: left;
          }
          &.title {
            width: 90%;
            text-align: left;
          }
          &.date-time {
            // vertical-align: top;
            div{
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              // .fa{
              //   margin-right: 2px;
              //   color: #ccc;
              // }
            }
            .date{

            }
            .time{
              font-style: italic;
            }
          }
          &.command{
            // min-width: 40px;
            // text-align: center;
            cursor: pointer;
          }
          // &:first-child {
          //   border-width: 1px;
          // }
          // &:last-child {
          //   border-width: 1px;
          // }
        }
      }
    }
  }
}

.news-item-title {
  padding-right: 105px;
  position: relative;
  .news-list_date-time {
    position: absolute;
    right: 0px;
    top: 0px;
    i {
      margin-right: 3px;
    }
    .news-list_time {
      margin-left: 10px;
    }
  }
}
