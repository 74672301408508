.coockie-modal-contaner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background: rgba(0,0,0,0.6);
  z-index: 999;
  color: #fff;
  padding: 10px;
  box-shadow: 0 0 4px 2px rgba(0,0,0,0.8);

    .coockie-content-container {
      text-align: center;
      width: 50%;

      @media (max-width: 500px) {
        width: 100% !important;
      }

        .button {
          margin: 0px 10px 10px;
        }
        .button-blue {
          float: none;
        }

        .remove-note {
            font-size: 12px;
        }
    }  
}