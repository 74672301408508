.timer_wrapper {
  margin: 6px;
  svg {
    fill: white;
    stroke: black;
    stroke-width: 1;
  }
  text {
    font-size: 24px;
    font-weight: bold;
    stroke: #aaa;
    fill:  #aaa;
    text-anchor: middle;
  }
  circle {
    fill: transparent;
    stroke: #dcdcdc;
  }
  path {
    fill: transparent;
  }

  .marks {
    stroke-width: 0.3;
    stroke: #000;  
    stroke-linecap: round;

    > line:nth-child(1) {
      transform: rotate(calc(1 * 30deg));
    }
    > line:nth-child(2) {
      transform: rotate(calc(2 * 30deg));
    }
    > line:nth-child(3) {
      transform: rotate(calc(3 * 30deg));
      stroke-width: 2;
    }
    > line:nth-child(4) {
      transform: rotate(calc(4 * 30deg));
    }
    > line:nth-child(5) {
      transform: rotate(calc(5 * 30deg));
    }
    > line:nth-child(6) {
      transform: rotate(calc(6 * 30deg));
      stroke-width: 2;
    }
    > line:nth-child(7) {
      transform: rotate(calc(7 * 30deg));
    }
    > line:nth-child(8) {
      transform: rotate(calc(8 * 30deg));
    }
    > line:nth-child(9) {
      transform: rotate(calc(9 * 30deg));
      stroke-width: 2;
    }
    > line:nth-child(10) {
      transform: rotate(calc(10 * 30deg));
    }
    > line:nth-child(11) {
      transform: rotate(calc(11 * 30deg));
    }
    > line:nth-child(12) {
      transform: rotate(calc(12 * 30deg));
      stroke-width: 2;
    }
  }
}