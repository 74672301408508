$borderColor: #bdc1c3;
$tableBottomBorderRadius: 8px;
$mobileResolution: 766px;
$grayFont: #999;
$white: #fff;

.table-ads {
  &.table-override-headers {
    border-top: none;
  }
  .table-transportads-header {
    th {
      height: 27px;
      text-align: center;
      vertical-align: middle;
      padding: 5px;
      border-bottom: 0;
      background-color: #36454f !important;
      color: #ffffff;
      border-left: 1px solid #cbcfd1;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      &:last-child {
        border-right: none;
      }
    }
    .th-date {
      min-width: 58px;
    }
    .th-favourite {
      min-width: 38px;
    }
  }
  .author-container {
    float: left;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    padding: 0px 5px;
    span {
      width: 100% !important;
    }
  }

  .transportads-modal {
    max-width: 730px;
    max-height: 90%;

    .widget-header_wrapper h4 {
      font-weight: 400;
    }

    .panel-static,
    .cargo-item_wrapper {
      height: 100%;
      margin-bottom: 0;
      border-radius: 10px;
    }

    .panel-body {
      height: calc(100% - 50px);
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba($color: #000, $alpha: 0.1);
      }
    }
  }

  .fav-container {
    width: 15%;
    padding-top: 0;
    display: flex;
  }
  .override-borders-header {
    tr {
      th {
        border-top: none !important;
      }
      th:first-child {
        border-left: none !important;
      }

      th:last-child {
        border-right: none !important;
      }
    }
  }
  .override-borders {
    tr {
      td {
        border-top: none !important;

        &:first-child {
          border-left: none;
          border-radius: 0;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }

        &:last-child {
          border-right: none;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }

      &:nth-child(5) {
        td {
          border-bottom-width: 1px !important;
        }
      }
    }
  }
  .small-table {
    tr:nth-last-child(2) {
      td {
        border-bottom-width: 2px;
        &:first-child {
          border-bottom-left-radius: $tableBottomBorderRadius;
        }
        &:last-child {
          border-bottom-right-radius: $tableBottomBorderRadius;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
  .transportads-line {
    height: 44px;
  }
}
  .table-transportads-body {
    tr {
      td {
        vertical-align: middle !important;
        padding: 0 5px;
        cursor: pointer;
        @media (max-width: $mobileResolution) {
          border: 0;
        }
        .no-transportads-text {
          text-align: center;
        }
      }
      &:last-child,
      &:nth-child(5) {
        @media (max-width: 766px) {
          border-bottom: 2px solid $borderColor;
        }
        td {
          border-bottom: 2px solid $borderColor;
          @media (max-width: 766px) {
            border: 0;
          }
          &:first-child {
            border-bottom-left-radius: $tableBottomBorderRadius;
          }
          &:last-child {
            border-bottom-right-radius: $tableBottomBorderRadius;
          }
        }
      }
      &:nth-child(7) {
        @media (max-width: $mobileResolution) {
          border-top: 2px solid $borderColor;
        }
        td {
          border-top: 2px solid $borderColor;
          @media (max-width: $mobileResolution) {
            border: 0;
          }
          &:first-child {
            border-top-left-radius: $tableBottomBorderRadius;
          }
          &:last-child {
            border-top-right-radius: $tableBottomBorderRadius;
          }
        }
      }
      .td-company {
        span {
          padding: 0;
          width: 190px;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        a {
          padding: 0;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .td-info {
        max-width: 528px;
        span {
          word-wrap: break-word;
        }
    }
      .td-date,
      .td-company {
        span:not(.icon) {
          @media (max-width: $mobileResolution) {
            color: $grayFont;
            font-size: 10px;
          }
        }
      }
    }
  }
}

.transportads-modal {
  max-width: 730px;
  max-height: 90%;
  .modal-content {
    .ads-modal-header {
      margin-bottom: 0px;
    }
    .ads-modal-wrapper {
      height: calc(100% - 45px);
    }
    .ads-modal-container {
      height: inherit;
      overflow-y: scroll;
      margin: 0;
      padding: 10px;

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
}
.ads-info-container{
  display: flex !important;
  justify-content: space-between;
}
