@import '../../palette/palette.scss';

@import './scss/03-form.scss';

body {
  overflow-x: hidden;
}
.panel-content-row {
  .text {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.show-search-modal {
  top: 0;
}
#menuOverlay.is-active {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  top: 0px;
}
@media (max-width: 990px) {
  .footer-bottom {
    .list-social,
    .social-block {
      display: none !important;
    }
  }
  .show-footer-content .logo-container {
    display: none !important;
  }
  .d-ib {
    display: block !important;
  }
  .footer .copyright {
    float: left;
    i {
      margin-right: 5px;
    }
  }
  .footer-top-mob .logo-container,
  .footer-top-mob .list-social {
    display: block !important;
  }
}
.d-ib {
  display: none;
}
.aita-logo {
  max-width: 75px;
  margin-top: -20px;
}
figure.table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px double #b3b3b3;
    tbody {
      tr {
        td {
          padding: 0.4em;
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 10;
}

a[disabled] {
  color: #ccc;
  //background-color: #ccc;
}

.menu-item {
  > div:first-of-type {
    display: flex;
    align-items: center;

    > div:first-of-type {
      display: flex !important;
      align-items: center;
    }
  }
  svg {
    margin-right: 3px;
  }
}

.react-confirm-alert-body {
  text-align: center !important;
  color: #000 !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
  button {
    background: #f56709 !important;
    color: #fff !important;
    box-shadow: 0px 3px 0px 0px #b34c08;
    height: 30px;
    padding: 0 10px !important;

    &:nth-child(2) {
      background: #36454f !important;
      box-shadow: 0px 3px 0px 0px #868686;
    }
  }
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}

@mixin table-cell() {
  line-height: 1.4;
  font-size: 12px;
}
.table {
  > tbody {
    > tr {
      > td {
        @include table-cell();
        // line-height: 1.4;
        // font-size: 12px;
      }
    }
  }
  > thead {
    > tr {
      > th {
        @include table-cell();
        // line-height: 1.4;
        // font-size: 12px;
      }
    }
  }
  // td {
  //   line-height: 1.4;
  // }
  // td.td-country{
  //   font-size: 12px;
  // }
  // td.td-date{
  //   min-width: 55px;
  // }
}

// input:placeholder-shown,
// // input::placeholder {
// //   color: $fieldPlaceholderColor;
// //   opacity: 1;
// // }

input::placeholder {
  color: $fieldPlaceholderColor;
  opacity: 1;
}
input::-moz-placeholder {
  color: $fieldPlaceholderColor;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: $fieldPlaceholderColor;
}
input::-ms-placeholder {
  color: $fieldPlaceholderColor;
}
input::-webkit-input-placeholder {
  color: $fieldPlaceholderColor;
}

body.react-confirm-alert-body-element {
  overflow: inherit !important;
}

.show-footer-content {
  display: flex !important;
}

.collapse-title {
  cursor: pointer !important;
}

// ::-webkit-input-placeholder {
//   color:red;
// }

// ::-moz-placeholder {
//   color:red;
// }

// ::-ms-placeholder {
//   color:red;
// }

// ::placeholder {
//   color:red;
// }

// .image-wrapper{
//   position: relative;

//   .img-container{
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 2;
//     cursor: pointer;
//   }
// }

.has-tooltip {
  cursor: help;
}

// .multi-select {
//   // z-index: 1010;
//   /* z-index: 10; */

//   margin-bottom: 13px;

//   // input[type="text"] {
//   //   height: 24px !important;
//   //   margin: 0 !important;
//   // }

//   .multi-select__menu {
//     z-index: 10;
//   }

//   .multi-select__input {
//     margin-top: -2px;

//     > input[type="text"] {
//       height: 24px !important;
//       margin: -2px 0 0 0 !important;
//     }
//   }

//   .multi-select__control,
//   & > div[class$="__control"] {
//     background-color: transparent;
//     min-height: 0 !important;
//     /* border-color: hsl(0,0%,80%) !important; */
//     outline: none;
//     /* border-color: #999 !important; */
//     border: 1px solid #cbcfd1 !important;
//     box-shadow: none;

//     &:focus-within {
//       border-color: #999 !important;
//       box-shadow: none;
//     }
//   }

//   .multi-select__indicators {
//     .multi-select__indicator {
//       align-self: flex-start;
//       position: relative;
//       color: #323232 !important;
//       min-height: 28px;
//       padding: 0;
//       width: 20px;

//       svg {
//         display: none;
//       }

//       &.multi-select__clear-indicator:after {
//         width: auto;
//         height: auto;
//         float: right;
//         content: "\f00d";
//         font: normal normal normal 11px/1 FontAwesome;
//         border: none;
//         position: absolute;
//         right: 7px;
//         top: 0.8em;
//       }
//       &.multi-select__dropdown-indicator:after {
//         width: auto;
//         height: auto;
//         float: right;
//         content: "\f0dc";
//         font: normal normal normal 11px/1 FontAwesome;
//         border: none;
//         position: absolute;
//         right: 7px;
//         top: 0.8em;
//       }
//     }
//   }
//   .multi-select__indicator-separator {
//     display: none;
//   }
//   /* .multi-select__indicator.multi-select__dropdown-indicator {
//     min-height: 28px;
//     padding: 0;
//     width: 20px;
//   } */

//   /* .multi-select__indicator.multi-select__clear-indicator {
//     min-height: 28px;
//     padding: 0;
//     width: 20px;
//   } */

//   .multi-select__value-container {
//     overflow-y: visible !important;

//     div:last-of-type {
//       height: 20px;
//     }

//     .multi-select__multi-value {
//       .multi-select__multi-value__label {
//         font-size: 12px;
//         padding: 2px 3px 2px 6px;
//       }
//     }
//   }
// }

footer .flex-container {
  justify-content: space-between !important;
}

.footer-info-section {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  .contact-support-item {
    margin-right: 10px;
  }

  .contact-info {
    margin-left: 100px;
  }

  .contact-info-row {
    .label {
      margin-right: 5px;
    }
  }

  .flex-basline {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
  }

  .mb-m10 {
    margin-bottom: 0px;
  }
}

footer {
  .contact-info-row {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 650px) {
  .footer-info-section {
    flex-direction: column;
    margin: 0px !important;
    .flex-basline {
      align-items: baseline;
      flex-direction: column;
      margin-bottom: 20px !important;
    }
  }
  .contact-info {
    margin-left: 0px !important;
  }
  .footer h6 {
    margin-bottom: 5px !important;
  }
}
@media (min-width: 651px) and (max-width: 991px) {
  .footer-info-section {
    flex-direction: column;
    margin: 0px !important;
    .flex-basline {
      align-items: baseline;
      flex-direction: column;
      margin-bottom: 20px !important;
    }
  }
  .contact-info {
    margin-left: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  .company-address {
    width: 180px;
  }
  .footer h6 {
    margin-bottom: 5px !important;
  }
}
