.show-submenu {
  display: block;
}
.header-top .filter-nav .priority-nav__dropdown.show-submenu > li > span {
  display: block;
  padding: 7px 10px;
}

.has-sublevel.is-opened {
  > a {
    color: #f56709 !important;
  }
  .sublevel {
    @media (max-width: 375px) {
      position: static;
    }
    display: block !important;
    opacity: 1 !important;
  }
}
.remove-icon {
  &.has-sublevel > a:after {
    content: none;
  }
}
.search-overlay {
  display: none;
}

@media (min-width: 375px) and (max-width: 650px) {
  .priority-nav__wrapper {
    position: inherit;
  }
}
@media (max-width: 767px) {
  .search-overlay {
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    position: absolute;
    width: 100%;
    display: block;
  }
  .header-top .search-container {
    height: 100%;
    background: none !important;
    display: block !important;
    border-radius: 0px !important;
  }

  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel:hover > a,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel:hover > span,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel > a,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel > span,
  .touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > a,
  .touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > span {
    color: #fff;
  }

  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel:hover > a:after,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel:hover > span:after,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel > a:after,
  .no-touch
    .header-top
    .filter-nav
    .priority-nav__dropdown.show
    > li.has-sublevel.has-sublevel.is-opened
    .sublevel
    > span:after,
  .touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > a:after,
  .touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > span:after {
    border-left-color: #fff;
  }

  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened:hover > a:after,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened:hover > span:after,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > a:after,
  .no-touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > span:after,
  .touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > a:after,
  .touch .header-top .filter-nav .priority-nav__dropdown.show > li.has-sublevel.is-opened > span:after {
    border-left-color: #f56709;
    transform: rotate(90deg);
  }

  // .no-touch .has-sublevel:hover .sublevel,
  .touch .has-sublevel.is-opened .sublevel {
    opacity: 0;
  }
  .menu-search-filter .has-sublevel:hover .sublevel,
  .menu-search-filter .has-sublevel.is-opened .sublevel {
    opacity: 1 !important;
  }
  .menu-search-filter {
    position: absolute;
    top: 10px;
    right: 90px;
  }
  .search-container input[type="text"],
  .search-container input[type="search"] {
    height: 50px;
    width: 88%;
    padding-right: 50%;
  }
  .additional-parent-container {
    background: #fff;
    width: 100%;
    height: 50px;
    position: absolute;
  }
}
//#f56709;
.menu-search-filter {
  display: block;
  & li {
    cursor: pointer;
  }
  & > li > a,
  & > li > span {
    padding: 0;
    color: #36454f;
    white-space: nowrap;
    line-height: 30px;
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .no-touch .has-sublevel:hover .sublevel,
  .touch .has-sublevel.is-opened .sublevel {
    left: 0px;
  }

  &.menu > li.has-sublevel > a:after,
  &.menu > li.has-sublevel > span:after {
    border-top-color: #36454f;
  }
}
.header-top .search-container {
  display: flex;
  background: #fff;
  border-radius: 10px;
}
.active-selected-filter {
  font-weight: 700;
}
