.company-confirm {
  ul {
    padding: 0px 40px;
    li {
      border-bottom: 1px solid #9a9a9a;
      list-style-type: none;
      padding: 10px 0px;
      border-top: 1px solid #9a9a9a;
      margin-top: -1px;
    }
  }

  .input-container {
    label {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .required {
    color: #dd2c00;
    margin-left: 2px;
  }
  .bold-underline {
    font-weight: 600;
    text-decoration: "underline";
  }
  .text-bold {
    font-weight: 600;
  }
  .input-file-upload {
    width: 108px;
    float: left;
    margin-right: 10px;
  }
  .file-name {
    line-height: 20px;
    font-weight: 700;
    color: #36454f;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: min-content;
  }
  .img-circle {
    background-size: cover;
    float: right;
  }
  .panel-header {
    padding-right: 15px;
  }
  @media screen and (max-width: 988px) {
  .clearfix {
    display: flex;
    flex-direction: column-reverse;
  }
}
  .table-employee {
    tr {
      td {
        padding: 7px 0;
        text-align: center;
      }
      th {
        text-align: center;
        span {
          font-weight: 600;
        }
      }
    }
  }
  .was-requested {
    padding: 20px;
    text-align: center;
  }
  .panel-block-row {
    // display: flex;
    // flex-direction: column;
    .upload-container {
      display: block;
      // justify-content: space-between;

      img {
        border-radius: 25px;
        width: 50px;
        height: 50px;
      }
    }
  }
  .file-upload-field_wrapper.photo-block {
    justify-content: center;
    width: 50px;
    height: 50px;
  }
  // .title-label {
  //   margin-bottom: 10px;
  //   text-align: center;
  // }
  // .file-uploader-child{
  //   display: flex;
  //   justify-content: center;
  // }
}
