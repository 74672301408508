.google-maps {
  .select-button {
    height: 30px;
    min-width: 30px;
    border: 1px solid #cbcfd1;
    border-radius: 5px;
    display: inline-block;
    margin-right: 4px;
    cursor: pointer;
  }
  &.panel-content-row {
    min-height: 300px;
  }
  .loading-place-checkbox {
    position: relative;
    left: 18px;
    top: 8px;
    font-size: 10.8px;
    width: 47%;
  }
  .transport-map {
    height: 100%;
    min-height: 660px;
  }

  .fullscreen-button {
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border-radius: 4px;
    // position: absolute;
    // top: 236px;
    // left: 34px;
    z-index: 2;
  }

  .directions-container {
    cursor: pointer;
    text-align: center;
    margin-bottom: 22px;
    margin-top: 10px;
    margin-right: 10px;
    height: 40px;
    z-index: 2;
  }

  .directions-button {
    color: #252525;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 16px;
    padding-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }

  .distance-box,
  .directions-container {
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border: 2px solid #fff;
    border-radius: 3px;
  }

  .bg-red {
    background-color: red;
  }

  .fullscreen-icon:hover {
    background-color: #f0f0f0;
  }

  .fullscreen-icon {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    svg {
      position: relative;
      top: 2px;
      left: 2px;
      height: 26px;
      width: 26px;
    }
  }

  .input-wrapper {
    border: 1px solid #cbcfd1;
    border-radius: 5px;
    width: 100%;
    height: 30px;
  }

  .input-field {
    width: 94%;
    height: 28px;
    border: 0;
  }

  .routing-plan-hidden {
    height: 0;
  }

  .map-input {
    display: inline-block;
  }

  .leaflet-routing-container-hide {
    position: relative;
    left: -1000px;
  }

  .map-paper {
    position: absolute;
    top: 56px;
    left: 42px;
    background-color: white;
    z-index: 9999;
    padding: 10px;
    border: 1px #cbcfd1 solid;
    border-radius: 5px;
    width: 294px;
    height: auto;

    .paper-element {
      padding-top: 6px;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // max-width: 100%;
      text-overflow: ellipsis;
      max-width: 290px;
      white-space: nowrap;
      overflow: hidden;
      height: 30px;
      cursor: pointer;

      span {
        font-size: 13px;
      }
      // border-top: 1px #cbcfd1 solid;
    }

    .paper-element:hover {
      background-color: #bfbfbf;
    }
  }
}
