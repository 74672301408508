.claims .col-md-12 {
    .panel {
        margin-bottom: 0px;
        box-shadow: none;
    }
    .panel-filter {
        color: #1b6ba8;
        span {
            cursor: pointer;
        }
        .active {
            span {
                color: #323232;
            }
        }
    }
    .banner-text-url {
        margin-top: 20px;
        display: block;
    }
    .filter-container-reviews {
        label {
            color: #323232;
        }
    }
    .panel-message-reply {
        .collapse-title {
            color: #1b6ba8;
        }
    }
    .panel-content-row {
        padding: 0px;
    }
    .panel-message-reviews {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e3e3e3;
        width: 100%;
        padding: 15px;
    }
    .panel-message-reviews:last-child,
    .no-margin,
    .no-border {
        margin-bottom: 0px !important;
        border-bottom: none !important;
    }
    .panel-message-reviews .panel-message-header .meta-message {
        margin: 0 0px 0px 30px;
    }
}
