$max-mobile-width: 991px;
.profile {
  width: 75%;
  @media screen and (max-width: $max-mobile-width) {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.note {
  width: 24%;

  @media screen and (max-width: $max-mobile-width) {
    width: 100%;
  }
}