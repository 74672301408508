@import '../../palette/palette.scss';

.button[disabled] {
  opacity: 0.55;
}

.form-group {
  padding-bottom: 15px;
  margin: 0;
}

input[type="checkbox"][value="false"] + label:before {
  content: "\f096";
  color: #999;
}

input[type="checkbox"][value="true"] + label:before {
  content: "\f046";
  color: #1b6ba8;
}

.validation-message {
  margin-top: -10px;
  color: red;
  //color: #dd2c00;
}

.grow {
  flex-grow: 1;
}
.max-250 { max-width: 250px; }
.max-300 { max-width: 300px; }

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:first-of-type{
      label {
        margin-left: 0;
      }
    }
    &.grow {
      input[type='text'].form-control {
        width: 100%;
      }
    }

    label {
      margin: 0 5px 0 10px;
      white-space: normal;
    }
  }
}

.news-add-edit {
  .form-group {
    padding: 0;
  }

  .label-1 {
    min-width: 80px;
    max-width: 80px;
  }

  .right-side {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    .image-container {
      flex-grow: 1;

      .image-wrapper {
        width: 130px;
        height: 100px;
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
      }
    }
    
    .fld-show{
      justify-content: flex-end;
    }
  }
  .buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    > button {
      min-width: 100px;
      margin-left: 10px;
    }
  }
}

.multi-select {
  // z-index: 1010;
  /* z-index: 10; */

  margin-bottom: 13px;

  // input[type="text"] {
  //   height: 24px !important;
  //   margin: 0 !important;
  // }

  .multi-select__menu {
    z-index: 10;
  }

  .multi-select__input {
    margin-top: -2px;

    > input[type="text"] {
      height: 24px !important;
      margin: -2px 0 0 0 !important;
    }
  }

  .multi-select__control,
  & > div[class$="__control"] {
    background-color: transparent;
    min-height: 0 !important;
    /* border-color: hsl(0,0%,80%) !important; */
    outline: none;
    /* border-color: #999 !important; */
    border: 1px solid #cbcfd1 !important;
    box-shadow: none;

    &:focus-within {
      border-color: #999 !important;
      box-shadow: none;
    }
  }

  .multi-select__indicators {
    .multi-select__indicator {
      align-self: flex-start;
      position: relative;
      color: #323232 !important;
      min-height: 28px;
      padding: 0;
      width: 20px;

      svg {
        display: none;
      }

      &.multi-select__clear-indicator:after {
        width: auto;
        height: auto;
        float: right;
        content: "\f00d";
        font: normal normal normal 11px/1 FontAwesome;
        border: none;
        position: absolute;
        right: 7px;
        top: 0.8em;
      }
      &.multi-select__dropdown-indicator:after {
        width: auto;
        height: auto;
        float: right;
        content: "\f0dc";
        font: normal normal normal 11px/1 FontAwesome;
        border: none;
        position: absolute;
        right: 7px;
        top: 0.8em;
      }
    }
  }
  .multi-select__indicator-separator {
    display: none;
  }
  /* .multi-select__indicator.multi-select__dropdown-indicator {
    min-height: 28px;
    padding: 0;
    width: 20px;
  } */

  /* .multi-select__indicator.multi-select__clear-indicator {
    min-height: 28px;
    padding: 0;
    width: 20px;
  } */

  .multi-select__value-container {
    overflow-y: visible !important;

    div:last-of-type {
      height: 20px;
    }

    .multi-select__multi-value {
      .multi-select__multi-value__label {
        font-size: 12px;
        padding: 2px 3px 2px 6px;
      }
    }
  }
}

/* .multi-select > div[class$="__control"] > div[class$="__value-container"]  input[type="text"] {
  height: 24px !important;
  margin: 0 !important;
} */
/* .multi-select > div[class$="__control"] > div[class$="__indicators"]  [class$="__indicator-separator"] {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
} */

// .chosen-drop {
//   .spinner {
//     margin-bottom: -15px;
//   }
// }

.my-dropdown {
  width: 100%;
  /* margin-bottom: 13px; */
  margin: 0;
  flex-direction: column;

  .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    border: 1px solid #cbcfd1;
    font-size: 12px;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dropdown-menu {
    padding: 0;
    min-width: 100%;
  }
}

.dropdown-toggle {
  box-shadow: none !important;

  &:focus {
    border-color: #999;
  }
  &::after {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.dropdown-menu {
  /* position: absolute;
  top: 100%;
  left: -9999px; */
  padding: 5px 5px;
  /* overflow: hidden; */
  max-height: 200px;
  overflow: auto;
  background: #fff;
  border: 1px solid #cbcfd1;
  border-radius: 10px;
  box-shadow: 1px 3px 8px 0px rgba(54, 69, 79, 0.1);
  white-space: nowrap;
  color: #323232;
  /* opacity: 0; */
  z-index: 21;
}

.my-dropdown .dropdown-toggle::after {
  width: auto;
  height: auto;
  float: right;
  /* margin: .4em -.2em 0 .5em; */
  content: "\f0dc";
  font: normal normal normal 11px/1 FontAwesome;
  border: none;
  position: absolute;
  right: 7px;
  top: 0.8em;
}

.react-datepicker {
  .react-datepicker-popper {
    z-index: 10;
  }
  .react-datepicker-popper .react-datepicker__navigation {
    height: 0;
  }
  /* .react-datepicker__input-container{
    background-image: none !important;
  } */

  .react-datepicker__input-container::after {
    /* width: auto;
    height: auto; */
    position: absolute;
    top: 0.75em;
    right: 5px;
    /* content: "\f0dc"; */
    content: "\f073";
    font: normal normal normal 12px/1 FontAwesome;
    /* border: none; */
  }
  /* .row > div {
    padding-left: 8px;
    padding-right: 8px;
  } */
}

.panel {
  flex-grow: 1;
}

.company-profile {
  .form-group {
    padding-bottom: 0;
  }
  .panel-header{
    padding-right: 15px;
  }
}

.chosen-container {
  .chosen-results {
    li.active-result {
      white-space: nowrap;
    }
  }
}
.input-text input[type="number"], .input-text input[type="text"] {
  padding-right: unset;
}

// input:placeholder-shown,
// input::placeholder {
//   color: $fieldPlaceholderColor;
//   opacity: 1;
// }

// input::-moz-placeholder {
//   color: $fieldPlaceholderColor;
//   opacity: 1;
// }
// input:-ms-input-placeholder {
//   color: $fieldPlaceholderColor;
// }
// input::-webkit-input-placeholder {
//   color: $fieldPlaceholderColor;
// }


// ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//   color: $fieldPlaceholderColor;
// }
// ::-moz-placeholder { /* Firefox 19+ */
//   color: $fieldPlaceholderColor;
// }
// :-ms-input-placeholder { /* IE 10+ */
//   color: $fieldPlaceholderColor;
// }
// :-moz-placeholder { /* Firefox 18- */
//   color: $fieldPlaceholderColor;
// }



// .card-header {
//   position: relative;
//   margin: -1px -1px 0;
//   /* padding: 10px 15px; */
//   padding: 0 15px;
//   min-height: 37px;
//   display: flex;
//   align-items: center;
//   background: #36454f;
//   border-radius: 9px 9px 0 0;
//   color: #fff;
//   *zoom: 1;
// }
// .card-header h4 {
//   font-size: 12px;
//   text-transform: uppercase;
//   font-weight: bold;
//   margin: 0;
//   letter-spacing: 1px;
//   word-spacing: 5px;
// }
// .card-header:first-child {
//   border-radius: 10px 10px 0 0;
// }
// .card-body {
//   padding: 15px;
// }
// .card-section {
//   padding: 13px 15px 15px;
//    /* 5px; */
//   border-top: 1px solid #ebeced;
// }
// .card-section:first-of-type{
//   border-top: none;
// }
// .card-section label,
// .card-section .label {
//   display: block;
//   min-height: 16px;
//   // margin-bottom: 6px;
//   font-weight: bold;
// }

// input[type="radio"] + label,
// input[type="checkbox"] + label {
//   font-weight: normal;
// }

// .card-row {
//   display: flex;
//   flex-wrap: wrap;
// }
// /* .card-row > div,
// .card-row > input, */
// .card-row > * {
//   margin-right: 15px;
//   /* margin-bottom: 0;
//   13px; */
// }
// .card-row > *:last-child {
//   margin-right: 0;
// }
// .card-row.larger-space-between > div {
//   margin-right: 30px;
// }
// .card-row.larger-space-between > div:last-child {
//   margin-right: 0;
// }
// .card-row.smaller-space-between > input,
// .card-row.smaller-space-between > div {
//   margin-right: 10px;
// }
// .card-row.smaller-space-between > input:last-child,
// .card-row.smaller-space-between > div:last-child {
//   margin-right: 0;
// }
// .card-row > div:last-of-type {
//   margin-left: 0;
// }
// .input-container {
//   display: flex;
//   align-items: center;
// }
// .input-container label,
// .input-container .label {
//   /* margin-bottom: 13px; */
//   margin: 0;
// }
// .input-container > label > i.fa {
//   margin: 0 4px;
// }

// .number-unit {
//   display: flex;
// }
// .number-unit .unit {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   min-width: 20px;
//   padding: .2em;
//   padding-right: 8px;
//   background-color: #fcf8e3;
//   /* line-height: 30px; */
//   /* text-align: right; */
//   /* vertical-align: middle; */
// }
// textarea{
//   margin: 0;
//   width: 100%;
//   flex-grow: 1;
//   border: 1px solid #cbcfd1;
//   border-radius: 5px;
// }
// textarea:focus{
//   border-color: #999;
// }

// i.tooltip-trigger {
//   margin: 0 5px;
//   cursor: pointer;
// }
// .popover{
//   background-color: #ffffcc;
// }
// /* .panel-body {
//   padding: 15px;
// } */

// .error-page {
//   min-height: 300px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }

// .error-page .error-message {
//   padding: 40px;
//   width: 300px;
//   height: 300px;
//   border-radius: 300px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 1px solid #999;
//   font-size: 16px;
//   background-color: rgb(248, 220, 195);
// }




// .form-buttons {
//   button {
//     margin: 30px 0 10px;
//     min-width: 115px;
//     & + a {
//       margin-bottom: 15px;
//     }
//   }

//   a {
//     /* display: inline-block; */
//     padding: 3px 0px;
//   }
// }

// .form-control {
//   display: block;
//   width: 100%;
//   height: 34px;
//   padding: 6px 12px;
//   font-size: 14px;
//   line-height: 1.42857143;
//   color: #555;
//   background-color: #fff;
//   background-image: none;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
//           box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
//   -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
//        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
//           transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
// }
// .form-control:focus {
//   border-color: #66afe9;
//   outline: 0;
//   -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
//           box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
// }
// .form-control::-moz-placeholder {
//   color: #999;
//   opacity: 1;
// }
// .form-control:-ms-input-placeholder {
//   color: #999;
// }
// .form-control::-webkit-input-placeholder {
//   color: #999;
// }
// .form-control::-ms-expand {
//   background-color: transparent;
//   border: 0;
// }
// .form-control[disabled],
// .form-control[readonly],
// fieldset[disabled] .form-control {
//   background-color: #eee;
//   opacity: 1;
// }
// .form-control[disabled],
// fieldset[disabled] .form-control {
//   cursor: not-allowed;
// }
// textarea.form-control {
//   height: auto;
// }

// .form-group {
//   margin-bottom: 15px;
// }
// .form-group-sm .form-control {
//   height: 30px;
//   padding: 5px 10px;
//   font-size: 12px;
//   line-height: 1.5;
//   border-radius: 3px;
// }
// .form-group-sm select.form-control {
//   height: 30px;
//   line-height: 30px;
// }
// .form-group-sm textarea.form-control,
// .form-group-sm select[multiple].form-control {
//   height: auto;
// }
// .form-group-sm .form-control-static {
//   height: 30px;
//   min-height: 32px;
//   padding: 6px 10px;
//   font-size: 12px;
//   line-height: 1.5;
// }
// .form-group-lg .form-control {
//   height: 46px;
//   padding: 10px 16px;
//   font-size: 18px;
//   line-height: 1.3333333;
//   border-radius: 6px;
// }
// .form-group-lg select.form-control {
//   height: 46px;
//   line-height: 46px;
// }
// .form-group-lg textarea.form-control,
// .form-group-lg select[multiple].form-control {
//   height: auto;
// }
// .form-group-lg .form-control-static {
//   height: 46px;
//   min-height: 38px;
//   padding: 11px 16px;
//   font-size: 18px;
//   line-height: 1.3333333;
// }

// .form-group:before,
// .form-group:after {
//   display: table;
//   content: " ";
// }

// .form-control {
//   box-shadow: none !important;
// }

// .form-row-buttons button + a {
//   margin-bottom: 15px;
// }

// label {
//   font-weight: bold;
// }

// input[type="checkbox"] + label {
//   display: inline-flex;
// }
