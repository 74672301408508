
.file-uploader_wrapper {
  position: relative;
  
  div {
    position: relative;
  }

  .click-wrapper {
    display: flex !important;
    align-items: center;
  }

  .file-uploader_spinner {
    svg {
      border-radius: 32px;
      // background-color: #fff;
    }
  }

//   flex-direction: column;
//   align-items: flex-start !important;
//   justify-content: flex-start !important;
//   padding: 10px;

//   .fine-uploader-dropzone-container {
//     position: relative;
//     // border: none;
//     border: 2px dashed #ddd;
//     min-height: 0;
//     max-height: 80px;
//     height: 80px;
//     // width: 130px;
//     width: 100%;
//     padding: 0;
//     border-radius: 0;

//     .file-uploader_click-wrapper {
//     //.react-fine-uploader-file-input-container {
//       padding: 0;
//       margin: 0;
//       align-items: center;
//       justify-content: center;
//       cursor: pointer;

//       &.file-uploader_click-centered{
//         display: flex !important;
//         flex-direction: column;
//         height: 80px;
//       }

//       .file-uploader_click-label-wrapper{
//         display: flex;
//         padding: 1px;
//         opacity: .6;
//         background-color: #fff;
//         svg{
//           margin-right: 2px;
//         }
//         cursor: pointer;
//       }
//       .react-fine-uploader-file-input{
//         cursor: pointer;
//       }
//     }

//     // .react-fine-uploader-thumbnail-placeholder 
    
//     // .react-fine-uploader-thumbnail 

// // .react-fine-uploader-gallery-files{
//     .react-fine-uploader-thumbnail-container{
//       position: absolute;
//       padding: 0;
//       margin: 0;
//       top: 0;
//       left: 0;
//       bottom: 0;
//       right: 0;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       overflow: hidden;
//       border: none;
    
//       .react-fine-uploader-thumbnail {
//         max-width: 130px;
//         max-height: 100px;
//       }
//       .react-fine-uploader-thumbnail-waiting {
//         max-height: 40px;
//         max-width: 40px;
//       }
//     }
//   }
//   .react-fine-uploader-delete-button {
//     position: absolute;
//     top: 0;
//     right: 0;
//     color: red;
//     padding: 0;
//     height: auto;
//     border: none;
//     font-size: 14px;
//   }
}