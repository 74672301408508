.gtu-gtc-container {
    display: flex;
    align-items: center;

    .fa-pencil {
        margin-left: 10px;
        margin-top: -2px;
    }
    input {
        padding: 0px;
        height: 20px;
        text-align: center;
        width: 70px;
    }
    i {
        margin-left: 10px;
        margin-right: 0px;
        cursor: pointer;
    }
    .spinner {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
    .disabled-input{
        background-color: #e3e3e3;
    }
}
