.recover-password_wrapper {
  // padding-top: 25px;

  .panel-block-inline {
    min-height: 70px;

    > div {
      vertical-align: top;

      .meta {
        margin-top: 20px;
      }

      @media (max-width: 767px) {
        .meta {
          margin-top: 0;
        }
      }
    }

    &.command-buttons {
      min-height: 0;
    }
  }
  .form-group {
    margin-bottom: 0;
    padding-bottom: 0;
    > label {
      .text {
        font-weight: unset;
      }
    }
    > div {
      height: 45px;

      .form-control {
        margin-bottom: 0;
      }
      .validation-message {
        margin-top: 0;
      }
    }
  }
}
