.profile-mini_wrapper {
  .name {
    width: auto;
    margin-right: 20px;
    max-width: 190px;
  }
  .profile-mini_info {
    padding: 20px 20px 30px;
  }
  .fa-envelope {
    margin-left: 10px;
    cursor: pointer;
  }
  .user-header .additional-info > span {
    margin-right: 5px;
  }
  .additional-container {
    display: flex;
    align-items: center;
    .user-header {
      display: flex;
    }
    .additional-info {
      display: flex;
      align-items: center;
      span {
        cursor: default;
      }
      @media screen and (max-width: 650px) {
        width: auto;
        .meta {
          display: block;
        }
      }
    }
  }

  .user-info-header-link {
    vertical-align: top;
  }
  div.name-sm {
    color: #323232 !important;
  }
  .date-registered-panel {
    width: 92px;
    display: inline-block;
    input {
      width: 72px;
      height: 20px;
      margin-right: 5px;
      padding: 0 1px;
      margin-left: -2px;
    }
    i {
      font-size: 14px;
      vertical-align: text-top;
      cursor: pointer;
      transition: 0.3;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .fa-pencil,
  .fa-list-alt {
    cursor: pointer;
  }
  .date-registered-line {
    display: inline-block;
    width: 92px;
    height: 20px;
    padding-top: 2px;
  }

  .full-user-info {
    height: 85px;
    border-top: #cbcfd1 solid 1px;

    .user-data {
      padding-left: 16px;
      .user-data-column {
        margin-right: 24px;
      }
      .access-status {
        text-align: center;
      }
    }

    .add-review {
      margin-top: 18px;
      margin-right: 16px;
    }
  }
  .settings {
    margin-left: auto;
    display: flex;

    .fa-list-alt {
      margin-top: 2px;
      cursor: pointer;
    }

    .fa-list-alt {
      margin-top: 2px;
      cursor: pointer;
    }
  }
  .settings-icon {
    .button-access {
      color: #1b6ba8;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .button-ban {
    cursor: pointer;
    color: gray;
  }
  .has-tooltip {
    cursor: pointer;
    .icon {
      display: none;
    }
  }

  .user-info-content {
    display: block;
    position: relative;
    .panel-filter-content {
      span {
        max-width: 100%;
        word-wrap: break-word;
      }
    }
    .start-chat {
      position: absolute;
      right: 0;
      margin: 40px 20px 0px 0px;
      color: #1b6ba8;
      @media screen and (max-width: 767px) and (min-width: 425px) {
        top: 25px;
      }
      cursor: pointer;
      .fa-comments {
        margin-right: 5px;
      }
    }
  }
  @media(max-width: 320px){
    .user-info-content .start-chat{
      top: 50px;
    }
  }
}
.access-status {
  text-align: left !important;
}