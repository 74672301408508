.summary-table-panel {
  border-radius: 0 0 9px 9px;
  overflow: hidden;

  .panel-filter {
    border: none
  }

  .summary-table-title {
    padding-left: 20px;
    background: #afb5b9;
    color: #fff;

    h6 {
      text-transform: uppercase;
      line-height: 35px;
      margin: 0;
    }
  }

  .summary-panel {
    display: flex;
    align-items: center;
  }

  .summary-panel-space-between {
    justify-content: space-between;
    padding-right: 20px;
  }

  .summary-customize {
    line-height: 45px;

    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .summary-table {
    width: 100%;
    display: block;

    th, td {
      text-align: center;
      font-weight: 400;
      border: 1px solid #cbcfd1;
      min-width: 80px;
      height: 25px;
    }
    tr {
      th:first-child, td:first-child {
        border-left: none;
      }
      th:last-child, td:last-child  {
        border-right: none;
      }
      &:last-child td {
        border-bottom: none;
      }
    }

    tbody tr {
      &:nth-child(2n - 1) {
        background: #ebeced;
      }

      &:hover {
        background: #afb5b9;
        color:#fff;
      }
    }

    .summary-table-country {
      padding-left: 20px;
      text-align-last: left;
      width: 100%;
    }
  }

  .summary-hidden-panel {
    transition: 1s;
  }

  .summary-hidden-animation {
    max-height: 0px !important;
  }

  .international-summary-table {
    th, td {
      min-width: 40px;
    }
  }
  
  .summary-mobile-on {
    display: none;
  
    div {
      width: 50%;
      text-align: center;
      line-height: 28px;
      border: 1px solid #cbcfd1;
    }
  }
  
  .mobile-item {
    background: #ebeced;
    color: #999;
  }
  
  .mobile-active {
    background: #fff;
    color: #000;
  }
  
  @media screen and (max-width: 450px) {
    .summary-table {
      th, td {
        min-width: 60px;
      }
    }

    .international-summary-table {
      th, td {
        min-width: 30px;
      }
    }

    .summary-mobile-on {
      display: flex;
    }

    .summary-mobile-off {
      display: none;
    }
  }
}

.widget-summary-table-panel {
  @media screen and (max-width: 1320px) and (min-width: 768px) {
    .summary-table {
      th, td {
        min-width: 60px;
      }
    }

    .international-summary-table {
      th, td {
        min-width: 30px;
      }
    }

    .summary-mobile-on {
      display: flex;
    }

    .summary-mobile-off {
      display: none;
    }
  }
}

.summary-pointer {
  cursor: pointer;
}

.international-summary-table-panel {
  border: 1px solid #cbcfd1;
  margin-bottom: 15px;
  border-radius: 10px;
}

.intranational-summary-table-panel {
  .summary-table-title {
    cursor: pointer;
  }
}
