.dropdown-base_wrapper{
  // width: 100%;
  display: flex;
  align-items: center;

  .chosen-single{
    // width: 100%;
    flex-grow: 1;
  }
  .has-tooltip {
    margin: 0 !important;
  }
  .chosen-drop {
    left: 0;
    
    .chosen-drop-item {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .dropdown {
    top: -9999px;
    opacity: 0;
  }
  &.is-opened {
    .dropdown {
      top: 100%;
      opacity: 1;
    } 
  }
}
