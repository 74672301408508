.ads-modal {
  max-width: 730px;
  max-height: 860px;
  .col-red {
    margin-right: 20px;
    &:hover {
      text-decoration: underline;
    }
  }
  .panel {
    margin-bottom: 0px;
    box-shadow: none;
  }
  .ads-modal-header {
    border-radius: 10px 10px 0px 0px;
  }
  .panel-header-menu .actions-panel .action-el {
    padding: 0 15px;
  }
  .item-container {
    border: none;
    .item-content {
      .item-container-links-wrapper {
        padding: 5px 0 !important;
      }
      .suplimentar-information {
        padding: 0 0 5px !important;
      }
      #ads-item-photos {
        padding: 0 0 5px !important;
      }
      .item-container-description {
        padding: 0;
      }
    }
  }
  .ads-modal-container {
    padding: 0px 5px 20px 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    .icons-links {
      padding-right: 0px;
    }
  }
  .ads-modal-wrapper {
    height: 100%;
    .links-wrapper {
      background: #ebeced;
      padding: 10px 15px;
    }
  }
  .col-red {
    cursor: pointer;
  }
  .ads-modal-container {
    height: calc(100% - 103px);

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
  }
}
.react-confirm-alert-overlay {
  z-index: 1070 !important;
}

.ads-modal-container {
  margin: 0px 10px;
}
.transportads-modal {
  .links-wrapper {
    padding: 0px 10px;
  }
  .col-red {
    margin-right: 10px;
  }
}

.ads-modal-header {
  border-radius: 10px 10px 0px 0px;
  .panel-header {
    padding-right: 15px;
  }
}
