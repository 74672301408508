.cargo-add_wrapper {
  .select-prices {
    .row {
      display: flex;
      align-items: center;

      .label {
        margin-bottom: 9px;
        font-weight: normal;
      }
    }
  }

  .cargo-add_tab {
    display: none;
    &.cargo-add_tab-active {
      display: block;
    }
  }
  .panel-form-col {
    margin-right: 10px;
  }
  .inputs-iconed {
    margin-top: 11px;
  }
  .cargo-add_adr-class {
    min-width: 40px;
  }

  .cargo-add_temperatures {
    display: inline-block;
    width: 60px !important;
    ul {
      text-align: right;
    }
  }
  .select-container-no-label {
    align-items: center;
    display: flex;
    height: 60px;
    input[type="checkbox"] + label {
      margin-bottom: 0px;
    }
  }
  .cargo-options {
    & > div {
      margin-right: 5px;
      padding: 4px 0;
    }
    &.additional-cargos-options {
      height: 48px;
      label {
        margin-bottom: 0px;
      }
      & > div {
        margin-right: 5px;
        padding: 4px 0;
        min-height: 40px;
        display: flex;
        align-items: center;
      }
      .temperatures-from-to_wrapper {
        display: flex;
        align-items: center;
        label {
          margin-right: 5px;
        }
      }
    }

    i {
      margin-left: 6px;
    }

    label {
      font-weight: normal;
    }

    .label-bold {
      font-weight: 500;
    }

    .cargo-types {
      width: 168px;

      .validation-message {
        margin-top: 10px;
      }
    }

    & > & {
      padding-top: 8px !important;
    }

    padding-bottom: 10px;
  }
  @media screen and (max-width: 500px) {
    .panel-header {
      align-items: center;
      flex-direction: column;
      h4 {
        margin-bottom: 5px;
      }
    }
  }
}

.cargo-add_calendar-selector {
  position: relative;

  .cargo-add_calendar-wrapper {
    position: absolute;
    top: 20px;
    z-index: 9;
  }
}

.cargo-add_modal {
  max-width: 450px;
}

.choose-cargo-type {
  width: 210px;
}

.captcha-position {
  text-align: center;

  .g-recaptcha {
    display: inline-block;
  }
}
.select-wrapper{
  align-items: center;
}
@media screen and (max-width: 450px) {
  .additional-cargos-options {
    height: auto !important;
  }
  .input-toggle-select input[type="checkbox"]:checked + label + .select-wrapper {
    display: flex !important;
    align-items: center;
    margin-left: 10px;
  }
  .choose-transport {
    width: 67%;
    flex-wrap: unset !important;
  }
  .prices-container {
    width: 100%;
    display: block !important;
    & > div {
      margin-right: 5px !important;
    }
  }
}
.allow-btn-container {
  display: flex;
  flex-direction: row-reverse;
  i {
    margin-top: 8px;
  }
}
