.reregistration-info-modal {
  max-width: 700px;
  padding: 0px;
  margin: 10px;

  .close {
    opacity: 1;
    font-size: 14px;
    transition: .3s;
    &:hover {
      color: #f56709
    }
  }

  .reregistration-modal {
    padding: 10px 20px 20px;

    h2 {
      text-align: center;
      margin: 10px 0 20px;
    }

    .text {
      font-size: 14px;
      margin-bottom: 10px;
    }

    b {
      text-transform: uppercase;
      color: #323232;
    }

    u {
      font-weight: bold;
      color: #323232;
    }

    .accept-panel {
      margin-top: 20px;
      text-align: center;
    }
  }
}
