$borderColor: #BDC1C3;
$tableBottomBorderRadius: 8px;
$mobileResolution: 766px;
$grayFont: #999;
$white: #fff;

#ads-main-list {
    margin-bottom: 15px;
}
#add-ads-button{
    height: 25px;
    width: 27px;
    margin-right: 10px;
    margin-top: -5px;
    padding: 0 8px;
    line-height: 25px;
}
#found-ads-total{
    padding-left: 15px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
}
.page-quantity{
    float: right;
}
.ads-list_{
    &header{
        padding: 10px 15px;
        box-sizing: border-box;
        .add-ads-link{
            text-decoration: none;
            outline: none;
            background-color: transparent;
            .link-text{
                color: $white;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}
.bordered-filters{
    border-right: 2px solid $borderColor;
    border-left: 2px solid $borderColor;
}
.m-l-5{
    margin-left: 5px;
}
#second-bordered-filter{
    padding-top: 16px;
    .page-quantity {
        display: flex;
        vertical-align: middle;
        .page-quantity-dropdown {
            width: 60px !important;
            height: 30px;
            margin-left: 10px;
        }
        #quantity-label {
            display: flex;
            align-items: center;
        }
    }
}

.not-auth-modal {
    padding: 0px !important;
    .close {
        margin-top: 10px;
        opacity: 1;
        font-size: 18px;
        transition: .3s;
        &:hover {
            color: #f56709
        }
    }
    .truck-item-bottom {
        height: 90px;
        padding: 35px 25px;
    }
    .fa-question-circle {
        margin-right: 1px;
    }
}
