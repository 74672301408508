.text-indent{
    text-indent:2em;
}
@media screen and (max-width: 410px) {
    .text-indent-none {
        text-indent: 0em;
    }
}
.bold{
    font-weight: bold;
}