.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  width: 100%;
  justify-content: space-between;
  &:hover {
    background: #dbf1ff;
    cursor: pointer;
  }

  .clear {
    margin: 0;
  }
  .name-company-container {
    display: flex;
    justify-content: space-between;
    .conversation-user-container {
      display: flex;
      flex-direction: column;
    }
  }
}
.conversation-info {
  text-align: left;
  width: 100%;
}

.conversation-user-company {
  font-weight: 500;
  color: #1b6ba8;
}

.conversation-photo {
  width: 50px;
  height: 50px !important;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
  float: left;
  color: #1b6ba8;
  &:hover {
    text-decoration: underline;
  }
}

.conversation-time-date {
  float: right;
  i {
    margin-right: 3px;
  }
}
.comment-date-time-container {
  display: flex;
  & > div {
    margin-left: 10px;
  }
}
.user-photo-container {
  width: 50px;
  margin-right: 10px;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
}
.unread-bubble {
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-conversation {
  background: #dbf1ff;
  /*
  .unread-bubble {
    background: #1b2227;
  }
  .conversation-title{
    color: #000; 
  }
  .conversation-info b{
    color: #000; 
  }
  .conversation-snippet{
    color: #000;
    font-weight: 700;
  }
*/
}
