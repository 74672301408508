.checkbox-flex {
  height: 100%;
  max-width: 600px;

}


.search-form {
  @media screen and (min-width: 1320px) {
    padding-right: 16px;
    order: 1 !important;
  }
}

.search-form-square-window {
  order: 2
}

.last-search {
  @media screen and (min-width: 1320px) {
    max-width: 40% !important;
    order: 2 !important;
  }
}

.panel-button-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
} 

.panel-button-row::before, .panel-button-row::after {
  content: none;
}

.last-search-square-window {
  max-width: 100%;
  order: 1;
}

.mobile-banner-on {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.mobile-banner-off {
  @media screen and (min-width: 768px) {
    display: none;
  }
}
